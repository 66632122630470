import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addSparePart, addSparePartGroup, getSparePart, getSparePartGroups, updateSparePart, updateSparePartGroup } from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";
import SparePartsAction from "./SparePartsAction";

const SparePartsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const modelId = queryParameters.get("model");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getSparePart({
      model:modelId
    }));
    dispatch(getSparePartGroups())
  }, []);
  const { getSparePartList, getSparePartGroupsList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
      <Row className="mt-4 mb-2 user-table"> 
          <Col md={12}>
            <h3>Spare Parts</h3>
          </Col> 
          <hr/>
          <Col md={12} className="mb-2">
            <SparePartsAction
             title="Add Spare Part"
             btnTitle="Add"
             api={addSparePart}
             btncls="w-100"
             btncolor={"success"}
             sparepartSelect={false}
             />
          </Col>
          <Col md={12}>
            {
              !!getSparePartList && getSparePartList?.map((data, index) => <span className="m-1" key={index}>
                      <SparePartsAction
                        title="Update Spare Part"
                        btnTitle={`${data?.name}-${data?.price}/-`}
                        name={data?.name}
                        description={data?.description}
                        status={data?.status}
                        id={data?.id}
                        price={data?.price}
                        api={updateSparePart}
                        image={data?.image}
                        spareparts={data?.spareparts}
                        btncolor={"secondary"} 
                        btncls="m-2"
                        sparepartSelect={false}
                      />
              </span>)
            }
          </Col>
        </Row>
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Spare Parts Groups</h3>
          </Col>  
          <hr/>
          <Col md={6}>
            <SparePartsAction
             title="Add Spare Part"
             btnTitle="Add Spare Part Group"
             api={addSparePartGroup}
             btncolor={"success"}
             sparepartSelect={true}
             />
          </Col>
        </Row>
        <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>SpareParts</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getSparePartGroupsList &&
              getSparePartGroupsList?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.name}
                    </td>
                    <td>{
                      data?.spareparts?.map((item, itemIndex) => <Fragment key={itemIndex}><b>{item?.name} - {item?.price}/-</b><br/></Fragment>)
                      }
                    </td>
                    <td>{data?.description}</td>
                    <td className="table-img">
                      {data?.status === true ? <img src={green} alt="success"/> : <img src={red} alt="inactive"/>}
                    </td>
                    <td>
                      <SparePartsAction
                        title="Update SparePart Group"
                        btnTitle="Edit"
                        name={data?.name}
                        description={data?.description}
                        status={data?.status}
                        id={data?.id}
                        api={updateSparePartGroup}
                        image={data?.image}
                        sparePart={data?.spareparts}
                        sparepartSelect={true}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default SparePartsList;
