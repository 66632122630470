import React, { useEffect, useRef, useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import imgborder from "../../images/border.png"
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { addItemInCart, addOrder, couponVerification, getDealers, getEnableVarient, getOrder, getProducts, updateOrder, varientWiseCropsSparePart } from "../../reducers/commonReducer";
import Header from "../../Components/Header";
import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router-dom";
import VarientImg from "./VarientImg";

const schema = yup.object().shape({
  model: yup.string().required(),
  varient: yup.string().required(),
  quantity: yup.number().required().positive().integer(),
  tractorModel : yup.string().required(),
  customerName : yup.string().required(),
  address : yup.string().required(),
  city : yup.string().required(),
  state : yup.string().required(),
  payPercentage : yup.string().required(),
  phone: yup.string()
  .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
  .required('Phone number is required')
});
const animatedComponents = makeAnimated();
function OrderAction(props) {
  const dispatch = useDispatch();
  const [varientPrice, setVarientPrice] = useState();
  const [varientImages, setVarientImages] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const width = useRef(window.innerWidth);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderId");
useEffect(() => {
  dispatch(getProducts());
  if(orderId){
    dispatch(getOrder({
      id:orderId
    }))
  }
  if(orderId || ((localStorage.getItem("userRole") === "manager") || (localStorage.getItem("userRole") === "admin") || (localStorage.getItem("userRole") === "superadmin"))){
    dispatch(getDealers())
  }
  },[])
  const { couponDetails, getOrderDetails,varientWiseSparePartList, freeCrops, varientWiseCropsList, getDealersList, getEnableVarientList, getProductsData, getEnableCropsList, getEnableSparePartList } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if(orderId && getOrderDetails){
         dispatch(getEnableVarient({model: getOrderDetails?.model?.id}))
         dispatch(varientWiseCropsSparePart({id:getOrderDetails?.varient?.id}))
    }
  },[getOrderDetails])

  const orderApi = async (values) => {
    const apiResponse = await dispatch(updateOrder(values));
    if (apiResponse?.payload?.status === 1 &&
      localStorage.getItem("x-auth-token") ) {
      return setTimeout(() => {
        navigate(`/orders`);
      }, 1000);
    }
    return null;
  };
  const cartApi = async (values) => {
    console.log("values", values)
    const apiResponse = await dispatch(addItemInCart({...values, paymentMethod}));
    if (apiResponse?.payload?.status === 1 &&
      localStorage.getItem("x-auth-token")) {
      const cartStatus = window.confirm("Are you sure you want to create the order?");
      if(cartStatus){
        const orderApiStatus = await dispatch(addOrder({...values, paymentMethod}))
        if(orderApiStatus?.payload?.status === 1){
          return setTimeout(() => {
            navigate(paymentMethod ? `/bank-details` : `/pay`); 
          }, 1000);
        }
      }else{
        window.location.reload();
      }
    }
    return null;
  };

  return (
    <>
    <Header/>
        <div className={`${width.current > 480 ? `p-5` : `p-1`}`}>
          <Row className="header-bg-color pt-2 pb-3">
            <Col className="text-center">
            <h3 className="mt-3 mb-0 fw-bold text-white">
              Add Order
            </h3>
            {/* <img className={width.current > 480 ? "width-auto" : "w-100"} alt="home" src={imgborder}/> */}
            </Col>
          </Row>
          <Formik
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={async (values, { resetForm }) => {
            orderId ? orderApi(values) : cartApi(values);
            resetForm({ values: "" });
          }}
          initialValues={{
            model : getOrderDetails?.model?.id,
            varient:getOrderDetails?.varient?.id,
            quantity:getOrderDetails?.quantity,
            crops: getOrderDetails?.crops?.map((crops) => crops?.id),
            spareparts: getOrderDetails?.spareparts?.map((sparePart) => sparePart?.id),
            tractorModel:getOrderDetails?.tractorModel,
            other:getOrderDetails?.other,
            customerName:getOrderDetails?.customerName,
            address:getOrderDetails?.customerAddress?.address,
            city:getOrderDetails?.customerAddress?.city,
            state:getOrderDetails?.customerAddress?.state,
            pincode:getOrderDetails?.customerAddress?.pincode,
            phone:getOrderDetails?.phone,
            dealer:getOrderDetails?.dealer?.id,
            status:getOrderDetails?.status,
            paymentStatus:getOrderDetails?.paymentStatus,
            id:orderId,
            freeCrops : getOrderDetails?.freeCrops,
            freeCropList: getOrderDetails?.freeCropList,
            payPercentage : getOrderDetails?.payPercentage, 
            coupon:'',
            paymentMethod: paymentMethod
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="mt-3">
                <Col md={12}>
                  <h4 className="text-success"><b>MODEL SELECTION</b></h4>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Label>Select Model</Form.Label>
                  <InputGroup>
                  <InputGroup.Text className="order-input-tab-icon">@</InputGroup.Text>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setFieldValue(`model`, e.target.value);
                      dispatch(getEnableVarient({
                        model:e.target.value
                      }))
                    }}
                    name="model"
                    className="form-control order-input-tab"
                    value={values.model}
                    isInvalid={!!errors.model}
                    disabled = {orderId ? true : false}
                  >
                    <option value="">Select Model</option>
                    {!!getProductsData && getProductsData?.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>{data.name}</option>
                      );  
                    })}
                  </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.model}
                      </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <Form.Label>Select Varient</Form.Label>
                  <InputGroup>
                  <InputGroup.Text className="order-input-tab-icon">@</InputGroup.Text>
                  <Form.Control
                    as="select"
                    onChange={async(e) => {
                      setFieldValue(`varient`, e.target.value);
                      setVarientPrice(e.target[e.target.selectedIndex].getAttribute("data-price"));
                      setVarientImages(e.target[e.target.selectedIndex].getAttribute("data-image"))
                      await dispatch(varientWiseCropsSparePart({
                        id:e.target.value
                      }))
                    }}
                    name="varient"
                    className="form-control order-input-tab"
                    value={values.varient}
                    isInvalid={!!errors.varient}
                    disabled = {orderId ? true : false}
                  >
                    <option value="">Select Varient</option>
                    {!!getEnableVarientList && getEnableVarientList?.map((data, index) => {
                      return (
                        <option key={index} value={data.id} data-price={data.price} data-image={data.image}>{data.name}</option>
                      );  
                    })}
                  </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.varient}
                      </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <Form.Group >
                    <Form.Label>Quantity</Form.Label>
                    <InputGroup >
                    <InputGroup.Text className="order-input-tab-icon">@</InputGroup.Text>
                    <Form.Control
                      className="order-input-tab"
                      type="number"
                      placeholder="Enter Quantity"
                      name="quantity"
                      value={values.quantity}
                      onChange={async(e) => {
                        await setFieldValue('quantity', e.target.value);
                        // setFieldValue('freeCrops', freeCrops);
                      }}
                      isInvalid={!!errors.quantity}
                      disabled = {orderId ? true : false}
                    />
                      <Form.Control.Feedback type="invalid">
                      {errors.quantity}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              {!!varientImages &&
                <VarientImg varientImages={varientImages}/>
              }
              {
                !orderId && <>
                <Row className="mb-3">
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <Form.Group >
                        <Form.Label className="text-danger">Free Crops {!!freeCrops && `(Any ${freeCrops} crops are free).`}</Form.Label>
                        <Select
                              className="order-input-tab"
                              closeMenuOnSelect = {false}
                              components={animatedComponents}
                              isMulti
                              value ={
                                !!varientWiseCropsList && varientWiseCropsList?.map((data) => {
                                    if (values?.freeCropList?.length && values.freeCropList.indexOf(data?.id) !== -1) {
                                      return {
                                        value: data?.id,
                                        label: data?.name,
                                        price: data?.price
                                      };
                                    }
                                  })
                                }
                              onChange={(selectedOptions) => {
                                if (selectedOptions.length > freeCrops) {
                                    alert(`Don't select more then ${freeCrops}`)
                                }
                                selectedOptions.length <= freeCrops && setFieldValue(`freeCropList`, selectedOptions?.map((data) => data?.value));
                              }}
                              options={
                                !!varientWiseCropsList &&
                                varientWiseCropsList?.map((data) => {
                                  if (!values?.crops?.some(item => item === data.id)) {
                                    return {
                                      value: data.id,
                                      label: data.name,
                                      price: data.price
                                    };
                                  }
                                  return null;
                                }).filter(Boolean)
                              }
                            />
                          <Form.Control.Feedback type="invalid">
                            {errors.freeCropList}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                  <Col md={6}>
                    <Form.Group >
                    <Form.Label>Crops</Form.Label>
                      <Select
                          className="order-input-tab"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            value ={
                              !!varientWiseCropsList && varientWiseCropsList?.map((data) => {
                                  if (values?.crops?.length && values.crops.indexOf(data?.id) !== -1) {
                                    return {
                                      value: data?.id,
                                      label: `${data.name} - ${data.price}/-`,
                                      price: data?.price
                                    };
                                  }
                                })
                              }
                            onChange={(selectedOptions) => {
                              setFieldValue(`crops`, selectedOptions?.map((data) => data?.value));
                              const totalPrice = selectedOptions.reduce((total, option) => total + option.price, 0);
                              setFieldValue('cropsprice', totalPrice);
                            }}
                            options={
                              !!varientWiseCropsList &&
                              varientWiseCropsList?.map((data) => {
                                if (!values?.freeCropList?.some(item => item === data.id)) {
                                  return {
                                    value: data.id,
                                    label: `${data.name} - ${data.price}/-`,
                                    price: data.price
                                  };
                                }
                                return null;
                              }).filter(Boolean)
                            }
                          />
                    <Form.Control.Feedback type="invalid">
                      {errors.crops}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                <Row>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Spare Part</Form.Label>
                    <Select
                            row={5}
                            className="order-input-tab"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            value ={
                              !!varientWiseSparePartList && varientWiseSparePartList?.map((data) => {
                                if (values?.spareparts?.length && values?.spareparts?.indexOf(data?.id) != -1) {
                                  return {
                                    value: data?.id,
                                    label: `${data?.name} - ${data?.price}`,
                                    price: data?.price
                                  };
                                }
                              })
                          }
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                `spareparts`,
                                selectedOptions?.map((data) => data?.value)
                              );
                              const totalPrice = selectedOptions.reduce((total, option) => total + option.price, 0);
                              setFieldValue('sparepartPrice', totalPrice);
                            }}
                            options={
                              !!varientWiseSparePartList &&
                              varientWiseSparePartList?.map((data) => {
                                return {
                                  value: data?.id,
                                  label: `${data?.name} - ${data?.price}`,
                                  price: data?.price
                                };
                              })
                            }
                          />
                    <Form.Control.Feedback type="invalid">
                      {errors.spareparts}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Tractor Model</Form.Label>
                    <InputGroup >
                    <InputGroup.Text className="order-input-tab-icon">@</InputGroup.Text>
                    <Form.Control
                      className="order-input-tab"
                      type="text"
                      placeholder="Enter Tractor Model"
                      name="tractorModel"
                      value={values.tractorModel}
                      onChange={handleChange}
                      isInvalid={!!errors.tractorModel}
                    />
                      <Form.Control.Feedback type="invalid">
                      {errors.tractorModel}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                </Row>
                </Col>
                </Row>
                </Col>
                <Col md={6}>
                    <Row>
                    <Col md={12} sm={12}>
                  <Form.Group >
                    <Form.Label>Any special demand in the machine</Form.Label>
                    <InputGroup >
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-pen-fill"></i></InputGroup.Text>
                    <Form.Control
                    className="order-input-tab"
                      type="text"
                      placeholder="Write anything......"
                      name="other"
                      value={values.other}
                      onChange={handleChange}
                      isInvalid={!!errors.other}
                      as="textarea" rows={3}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.other}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
                    </Row>
                </Col>
              </Row>
                </>
              }
              {/* <Row className="mb-3">
                
              </Row> */}
              {localStorage.getItem("userRole") !== "dealer" &&
              <Row className="mb-3">
                <Col md={3}>
                  <Form.Label>Dealer</Form.Label>
                  <InputGroup>
                  <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-people-fill"></i></InputGroup.Text>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("dealer", e.target.value);
                    }}
                    name="dealer"
                    className="form-control order-input-tab"
                    value={values.dealer}
                    isInvalid={!!errors.dealer}
                  >
                    <option value="">Select Dealer</option>
                    {
                      !!getDealersList && getDealersList?.map((data) => <option value={data?.id}>{data?.name}</option>)
                    }
                  </Form.Control>
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <Form.Label>Status</Form.Label>
                  <InputGroup>
                  <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-radioactive"></i></InputGroup.Text>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control order-input-tab"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={0}>Pending</option>
                    <option value={2}>Processing</option>
                    <option value={3}>Shipping</option>
                    <option value={4}>Completed</option>
                    <option value={1}>Cancel</option>
                  </Form.Control>
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <Form.Label>Payment Status</Form.Label>
                  <InputGroup >
                  <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-cash-coin"></i></InputGroup.Text>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("paymentStatus", e.target.value);
                    }}
                    name="paymentStatus"
                    className="form-control order-input-tab"
                    value={values.paymentStatus}
                    isInvalid={!!errors.paymentStatus}
                  >
                    <option value="">Select Payment Status</option>
                    <option value={"Pending"}>Pending</option>
                    <option value={"Success"}>Success</option>
                  </Form.Control>
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <Form.Group >
                    <Form.Label>Expected Date</Form.Label>
                    <InputGroup>
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-calendar-check-fill"></i></InputGroup.Text>
                    <Form.Control
                      type="date"
                      className="order-input-tab"
                      placeholder="Enter Tractor Model"
                      name="deliveredDate"
                      value={values.deliveredDate}
                      onChange={handleChange}
                      isInvalid={!!errors.deliveredDate}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.deliveredDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>}
              <Row className="mt-3">
                <Col md={12}>
                  <h4 className="text-success"><b>CUSTOMER DETAILS</b></h4>
                </Col>
              </Row>
              {/* <Row>
                <Col className="text-center">
                <h3 className="mt-3 mb-0 fw-bold">
                Customer Details
                </h3>
                <img alt="home" className={width.current > 480 ? "width-auto" : "w-100"} src={imgborder}/>
                </Col>
              </Row> */}
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group >
                    <Form.Label>Customer Name</Form.Label>
                    <InputGroup>
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-person-circle"></i></InputGroup.Text>
                    <Form.Control
                      type="text"
                      className="order-input-tab"
                      placeholder="Enter Customer Name"
                      name="customerName"
                      value={values.customerName}
                      onChange={handleChange}
                      isInvalid={!!errors.customerName}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.customerName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group >
                    <Form.Label>State</Form.Label>
                    <InputGroup>
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-geo-alt-fill"></i></InputGroup.Text>
                    <Form.Control
                    className="order-input-tab"
                      type="text"
                      placeholder="Enter State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group >
                    <Form.Label>District</Form.Label>
                    <InputGroup >
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-geo-alt-fill"></i></InputGroup.Text>
                    <Form.Control
                    className="order-input-tab"
                      type="text"
                      placeholder="Enter District"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                    />
                    </InputGroup>
                    
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={4}>
                  <Form.Group >
                    <Form.Label>Pin Code</Form.Label>
                    <InputGroup >
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-geo-alt-fill"></i></InputGroup.Text>
                    <Form.Control
                      type="text"
                      className="order-input-tab"
                      placeholder="Enter PIN Code"
                      name="pincode"
                      value={values.pincode}
                      onChange={handleChange}
                      isInvalid={!!errors.pincode}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.pincode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              <Col md={4}>
                  <Form.Group >
                    <Form.Label>Address</Form.Label>
                    <InputGroup>
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-geo-alt-fill"></i></InputGroup.Text>
                    <Form.Control
                      type="text"
                      className="order-input-tab"
                      placeholder="Enter Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group >
                    <Form.Label>Phone</Form.Label>
                    <InputGroup >
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-telephone-fill"></i></InputGroup.Text>
                    <Form.Control
                      className="order-input-tab"
                      type="number"
                      placeholder="Enter Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {(!orderId && !!values?.varient) && <Row className="mb-3">
                <Col md={4}>
                  <Form.Group >
                    <Form.Label>Coupon Code</Form.Label>
                    <InputGroup >
                    <InputGroup.Text className="order-input-tab-icon"><i className="bi bi-gift-fill"></i></InputGroup.Text>
                    <Form.Control
                    className="order-input-tab"
                      type="text"
                      placeholder="Enter Coupon Code"
                      name="coupon"
                      value={values.coupon}
                      onChange={async (e) => {
                        setFieldValue("coupon", e.target.value);
                        if(e.target.value?.length === 8){
                          dispatch(couponVerification({
                            name: e.target.value,
                            varient: values?.varient
                          }))
                        }
                      }}
                      isInvalid={!!errors.coupon}
                    />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.coupon}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {couponDetails?.price && <p className="text-white p-2 mb-0 bg-success"><b>You got {couponDetails?.price * values?.quantity}/- discount on this order.</b></p>}
                </Col>
                <Col md={4}>
                  <Form.Label>Amount Pay Percentage</Form.Label>
                  <InputGroup>
                  <InputGroup.Text className="order-input-tab-icon">@</InputGroup.Text>
                  <Form.Control
                    as="select"
                    onChange={async(e) => {
                      setFieldValue(`payPercentage`, e.target.value);
                    }}
                    name="payPercentage"
                    className="form-control order-input-tab"
                    value={values.payPercentage}
                    isInvalid={!!errors.payPercentage}
                    disabled = {orderId ? true : false}
                  >
                    <option value="">Select pay Percentage</option>
                    <option value={"10"}>10%</option>
                    <option value={"20"}>20%</option>
                    <option value={"30"}>30%</option>
                    <option value={"40"}>40%</option>
                    <option value={"50"}>50%</option>
                    <option value={"60"}>60%</option>
                    <option value={"70"}>70%</option>
                    <option value={"80"}>80%</option>
                    <option value={"90"}>90%</option>
                    <option value={"100"}>100%</option>
                  </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.payPercentage}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>}
              {orderId && <Row>
                <Col>
                <Button className="w-100" variant="success" type="submit">Update</Button>
                </Col>
              </Row>}
              {(!!values?.payPercentage && !orderId) && <Row>
                <Col md={6}>
                  <Button
                  onClick={() => setPaymentMethod(true)}
                  className="w-100" variant="info" type="submit">
                  <i className="bi bi-bank2"></i> RTGS <b>{((values?.payPercentage / 100) * (
                    (values?.sparepartPrice ? (+values?.sparepartPrice) : 0) + 
                    (values?.cropsprice ? (+values?.cropsprice * (values?.quantity ? values?.quantity : 0)) : 0) + 
                    (varientPrice ? (+varientPrice * (values?.quantity ? values?.quantity : 0)) : 0) - 
                    (couponDetails?.price ? couponDetails?.price * values?.quantity : 0)
                    )).toFixed(0)} /-</b> 
                  </Button>
                </Col>
                <Col md={6}>
                  <Button 
                  onClick={() => setPaymentMethod(false)}
                  className="w-100" variant="success" type="submit">
                  Pay <b>{((values?.payPercentage / 100) * (
                    (values?.sparepartPrice ? (+values?.sparepartPrice) : 0) + 
                    (values?.cropsprice ? (+values?.cropsprice * (values?.quantity ? values?.quantity : 0)) : 0) + 
                    (varientPrice ? (+varientPrice * (values?.quantity ? values?.quantity : 0)) : 0) - 
                    (couponDetails?.price ? couponDetails?.price * values?.quantity : 0)
                    )).toFixed(0)} /-</b> <span>(2% Payment Gateway charges apply on payment time.)</span>.
                  </Button>
                </Col>
              </Row>}
            </Form>
          )}
        </Formik>
        </div>
    </>
  );
}

export default OrderAction;
