import React from "react";
import logo from "../images/logo.jpg";
import { Col, Container, Row } from "react-bootstrap";

const CancellationRefundPolicy = () => {
  return (
    <Container>
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
            <h1><b>Cancellation And Refund Policy</b></h1>
            <p>We accept all cancellations made before the cut-off time of our shipping slot (3 hours). </p>
            <p>Please reach out to our customer services at support@nvtthresher.com for cancellation requests and refunds. We refund all payments made for orders placed as a store credit, which can be used by customers for future purchases.</p>
            <p>If we suspect any fraudulent transaction or transactions violating the terms of website use, we are at sole discretion to cancel such orders. These customers/accounts will be denied access from the use of site or purchase of products in the future. Refund processing time will be 15 days after refund/exchange request.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CancellationRefundPolicy;
