import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
  postApiFile,
  fileWithObjArray,
  multiImageUpload,
} from "../helpers/apiStructure";

var baseUrl = "http://localhost:3000";
// In case instead of localhost
if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}

//dashboard
export const getDashboard = createAsyncThunk("getDashboard", async (body) => {
  const result = await postApi(`${baseUrl}/api/dashboard`, "get", body);
  return result;
});
//get location
export const getLatLogLocation = createAsyncThunk("getLatLogLocation", async (body) => {
  const result = await postApi(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${body?.lat}&lon=${body?.long}&zoom=18&addressdetails=1`, "get", body);
  return result;
});
//users
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/main`, "post", body);
  return result;
});
export const getDealers = createAsyncThunk("getDealers", async (body) => {
  const result = await postApi(`${baseUrl}/api/dealers`, "get");
  return result;
});
export const getUser = createAsyncThunk("getUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/profile`, "post", body);
  return result;
});
export const userUpdate = createAsyncThunk("userUpdate", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/user/update/${body?.id}`, "put", body);
  await messages(result?.message, result?.status);
  return result;
});
//resetdata
export const resetOrderRecord = createAsyncThunk("resetOrderRecord", async (data) => {
  return [];
});
//resetdata
export const resetUsers = createAsyncThunk("resetUsers", async (data) => {
  return [];
});
//resetpayment
export const resetPayment = createAsyncThunk("resetPayment", async (data) => {
  return [];
});
//reset transaction
export const resetTransaction = createAsyncThunk("resetTransaction", async (data) => {
  return [];
});
//reset orders
export const resetOrders = createAsyncThunk("resetOrders", async (data) => {
  return [];
});
//technicains
export const getTechnicains = createAsyncThunk("getTechnicains", async (body) => {
  const result = await postApi(`${baseUrl}/api/technicains`, "post", body);
  return result;
});
//complaint
export const getComplaint = createAsyncThunk("getComplaint", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/complaint/main/list`,
    "post",
    body
  );
  return result;
});
export const addComplaint = createAsyncThunk("addComplaint", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/complaint/add`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const updateComplaint = createAsyncThunk("updateComplaint", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/complaint/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const assignComplaint = createAsyncThunk("assignComplaint", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/assign/complain`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const complaintFeedback = createAsyncThunk("complaintFeedback", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/complaint/feedback`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
//resetdata
export const resetComplaints = createAsyncThunk("resetComplaints", async (data) => {
  return [];
});
// crop
export const getCrops = createAsyncThunk("getCrops", async (body) => {
  const result = await postApi(`${baseUrl}/api/crop/list`, "post", body);
  return result;
});
export const getEnableCrops = createAsyncThunk("getEnableCrops", async (body) => {
  const result = await postApi(`${baseUrl}/api/crops?model=${body?.model}`, "get");
  return result;
});
export const addCrops = createAsyncThunk("addCrops", async (body) => {
  const result = await postApi(`${baseUrl}/api/Crop/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateCrops = createAsyncThunk("updateCrops", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/Crop/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const addCropsGroup = createAsyncThunk("addCropsGroup", async (body) => {
  const result = await postApi(`${baseUrl}/api/cropgroup/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getCropsGroups = createAsyncThunk("getCropsGroups", async (body) => {
  const result = await postApi(`${baseUrl}/api/cropgroup/list`, "post", body);
  return result;
});
export const getAllCrops = createAsyncThunk("getAllCrops", async (body) => {
  const result = await postApi(`${baseUrl}/api/cropgroups`, "get", body);
  return result;
});
export const updateCropsGroup = createAsyncThunk("updateCropsGroup", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/cropgroup/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// spare parts
export const getSparePart = createAsyncThunk("getSparePart", async (body) => {
  const result = await postApi(`${baseUrl}/api/spatepart/list`, "post", body);
  return result;
});
export const getEnableSparePart = createAsyncThunk("getEnableSparePart", async (body) => {
  const result = await postApi(`${baseUrl}/api/spareparts?model=${body?.model}`, "get");
  return result;
});
export const addSparePart = createAsyncThunk("addSparePart", async (body) => {
  const result = await postApi(`${baseUrl}/api/spatepart/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSparePart = createAsyncThunk("updateSparePart", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/spatepart/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const getSparePartGroups = createAsyncThunk("getSparePartGroups", async (body) => {
  const result = await postApi(`${baseUrl}/api/spatepart/group/list`, "post", body);
  return result;
});
export const getAllSparePartGroups = createAsyncThunk("getAllSparePartGroups", async (body) => {
  const result = await postApi(`${baseUrl}/api/sparepart/groups`, "get", body);
  return result;
});
export const addSparePartGroup = createAsyncThunk("addSparePartGroup", async (body) => {
  const result = await postApi(`${baseUrl}/api/spatepart/group/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSparePartGroup = createAsyncThunk("updateSparePartGroup", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/spatepart/group/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// Varient
export const getVarient = createAsyncThunk("getVarient", async (body) => {
  const result = await postApi(`${baseUrl}/api/varient/main`, "post", body);
  return result;
});
export const getEnableVarient = createAsyncThunk("getEnableVarient", async (body) => {
  const result = await postApi(`${baseUrl}/api/varients`, "post", body);
  return result;
});
export const getVarientWithoutPage = createAsyncThunk("getVarientWithoutPage", async (body) => {
  const result = await postApi(`${baseUrl}/api/varient/list`, "get");
  return result;
});
export const addVarient = createAsyncThunk("addVarient", async (body) => {
  const result = await multiImageUpload(`${baseUrl}/api/varient/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateVarient = createAsyncThunk("updateVarient", async (body) => {
  const result = await multiImageUpload(
    `${baseUrl}/api/varient/update/${body?.id}`, "put", body);
  await messages(result?.message, result?.status);
  return result;
});
//coupon
export const addCouponCode = createAsyncThunk("addCouponCode", async (body) => {
  const result = await postApi(`${baseUrl}/api/coupon/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getCouponList = createAsyncThunk("getCouponList", async (body) => {
  const result = await postApi(`${baseUrl}/api/coupon/main`, "post", body);
  return result; 
});
export const updateCoupon = createAsyncThunk("updateCoupon", async (body) => {
  const result = await postApi(`${baseUrl}/api/coupon/update/${body?.id}`, "put", body);
  await messages(result?.message, result?.status);
  return result;
});
export const couponVerification = createAsyncThunk("couponVerification", async (body) => {
  const result = await postApi(`${baseUrl}/api/coupon`, "post", body);
  await messages(result?.message, result?.status);
  return result; 
});
// Products Mgt
export const getProducts = createAsyncThunk("getProducts", async (body) => {
  const result = await postApi(`${baseUrl}/api/model/main`, "post", body);
  return result;
});
export const addProduct = createAsyncThunk("addProduct", async (body) => {
  const result = await postApi(`${baseUrl}/api/model/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const singleProduct = createAsyncThunk("singleProduct", async (body) => {
  const result = await postApi(`${baseUrl}/api/getmodel/${body?.id}`, "post", body);
  return result;
});
export const updateProduct = createAsyncThunk("updateProduct", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/model/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const getEnabledProducts = createAsyncThunk(
  "getEnabledProducts",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/model/list`, "post", body);
    return result;
  }
);
//Order Mgt
export const getOrders = createAsyncThunk("getOrders", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/list`, "post", body);
  return result;
});
export const getOrder = createAsyncThunk("getOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/getorder`, "post", body);
  return result;
});
export const addOrder = createAsyncThunk("addOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateOrder = createAsyncThunk("updateOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const varientWiseCropsSparePart = createAsyncThunk("varientWiseCropsSparePart", async (body) => {
  const result = await postApi(`${baseUrl}/api/listby/varient`, "post", body);
  return result;
});
export const groupWiseCrops = createAsyncThunk("groupWiseCrops", async (body) => {
  const result = await postApi(`${baseUrl}/api/groupwise/crops`, "post", body);
  return result;
});
//pending amount
export const pendingAmountPay = createAsyncThunk("pendingAmountPay", async (body) => {
  const result = await postApi(`${baseUrl}/api/pending/amountpay`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//cart
export const addItemInCart = createAsyncThunk("addItemInCart", async (body) => {
  const result = await postApi(`${baseUrl}/api/cart/add`, "post", body);
  // await messages(result?.message, result?.status);
  return result;
});
//transaction Mgt
export const getTransaction = createAsyncThunk("getTransaction", async (body) => {
  const result = await postApi(`${baseUrl}/api/transactions`, "post", body);
  return result;
});
//Payment Mgt
export const getPayments = createAsyncThunk("getPayments", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/list`, "post", body);
  return result;
});
export const getPayment = createAsyncThunk("getPayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/details`, "post", body);
  return result;
});
export const addPayment = createAsyncThunk("addPayment", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/payment/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updatePayment = createAsyncThunk("updatePayment", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/payment/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// Categories Mgt
export const getCategories = createAsyncThunk("getCategories", async (body) => {
  const result = await postApi(`${baseUrl}/api/main/categories`, "post", body);
  return result;
});
export const addCategory = createAsyncThunk("addCategory", async (body) => {
  const result = await postApi(`${baseUrl}/api/category/add`, "post", body);
  return result;
});
export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/category/update/${body?.id}`,
      "put",
      body
    );
    return result;
  }
);
export const getEnabledCategories = createAsyncThunk(
  "getEnabledCategories",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/categories/`, "post", body);
    return result;
  }
);
// Types Mgt
export const getTypes = createAsyncThunk("getTypes", async (body) => {
  const result = await postApi(`${baseUrl}/api/main/types`, "post", body);
  return result;
});
export const getEnabledTypes = createAsyncThunk(
  "getEnabledTypes",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/types`, "post", body);
    return result;
  }
);
export const addType = createAsyncThunk("addType", async (body) => {
  const result = await postApi(`${baseUrl}/api/type/add`, "post", body);
  return result;
});
export const updateType = createAsyncThunk("updateType", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/type/update/${body?.id}`,
    "put",
    body
  );
  return result;
});
//send otp
export const sendOtp = createAsyncThunk("sendOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpSend`, "post", body);
  result?.status === 1 && localStorage.setItem("otpPhone", body?.phone);
  await messages(result?.message, result?.status);
  return result;
});
// Login
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const verifyOtp = createAsyncThunk("verifyOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpVerify`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const createNewPassword = createAsyncThunk(
  "createNewPassword",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/forgetPassword`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "get", body);
  return result;
});
export const userSignup = createAsyncThunk("userSignup", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/user/signup`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//user Profile
export const userProfile = createAsyncThunk("userProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/getuser`, "get", body);
  return result;
});
export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (body) => {
    const result = await fileWithObjArray(
      `${baseUrl}/api/user/updateprofile`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// settings
export const getSettings = createAsyncThunk("getSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings`, "post", body);
  return result;
});
export const addSettings = createAsyncThunk("addSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/setting/update/${body?.id}`,
    "put",
    body
  );
  // await messages(result?.message, result?.status);
  return result;
});

export const addUser = createAsyncThunk("addUser", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/user/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const updateUser = createAsyncThunk("updateUser", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});

// State
export const getStates = createAsyncThunk("getStates", async (body) => {
  const result = await postApi(`${baseUrl}/api/statecity/states`, "post", body);
  return result;
});

// Cities
export const getCities = createAsyncThunk("getCities", async (body) => {
  const result = await postApi(`${baseUrl}/api/statecity/cities`, "post", body);
  return result;
});

const userReducer = createReducer(
  {
    // counter: 0,
    // sumOfNumberPayloads: 0,
    // unhandledActions: 0,
  },
  (builder) => {
    builder
      // Login
      .addCase(adminLogin.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload.token);
          localStorage.setItem("userRole", action.payload?.me?.role?.slug);
          localStorage.setItem("userid", action.payload?.me?.id);
          localStorage.setItem("dealerId", action.payload?.me?.username);
        }
      })
      // Dashboard
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboardData = action.payload;
      })
      // State
      .addCase(getStates.fulfilled, (state, action) => {
        state.getStatesData = action.payload;
      })
      // Cities
      .addCase(getCities.fulfilled, (state, action) => {
        state.getCitiesData = action.payload;
      })
      //user Profile
      .addCase(getUser.fulfilled, (state, action) => {
        state.getUserDetails = action.payload?.user;
      })
      .addCase(userProfile.fulfilled, (state, action) => {
        state.userProfileData = action.payload?.user;
        state.userSelectProduct = action.payload?.selectedProducts;
      })
      // User list
      .addCase(getUsers.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUsersData)) {
            state.getUsersData = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getUsersData = [...state.getUsersData, ...newUserList];
        state.getUsersDataBlank = action.payload.list;
        state.userCount = action.payload?.count
      })
      .addCase(getDealers.fulfilled, (state, action) => {
        state.getDealersList = action.payload?.list;
      })
      //reset users
      .addCase(resetOrderRecord.fulfilled, (state, action) => {
        state.getOrderDetails = [];
      })
      //reset users
      .addCase(resetUsers.fulfilled, (state, action) => {
        state.getUsersData = [];
      })
      //reset payment 
      .addCase(resetPayment.fulfilled, (state, action) => {
        state.getPaymentsList = [];
      })
      //reset transaction 
      .addCase(resetTransaction.fulfilled, (state, action) => {
        state.TransactionList = [];
      })
      //reset orders 
      .addCase(resetOrders.fulfilled, (state, action) => {
        state.getOrdersData = [];
      }) 
      //Technicains
      .addCase(getTechnicains.fulfilled, (state, action) => {
        state.technicainsList = action.payload?.list;
      })
      //Complaint
      .addCase(getComplaint.fulfilled, (state, action) => {
        state.getComplaintList = [...state.getComplaintList, ...action.payload?.list];
        state.getComplaintListBlank = action.payload?.list;
        state.getComplaintCount = action.payload?.count;
      })
      //reset Complaint
      .addCase(resetComplaints.fulfilled, (state, action) => {
        state.getComplaintList = [];
      })
      // Products list
      .addCase(getProducts.fulfilled, (state, action) => {
        state.getProductsData = action.payload?.list;
      })
      .addCase(singleProduct.fulfilled, (state, action) => {
        state.singleProductData = action.payload?.model;
      })
      .addCase(getEnabledProducts.fulfilled, (state, action) => {
        state.getEnabledProductsData = action.payload?.list;
      })
      //coupon
      .addCase(getCouponList.fulfilled, (state, action) => {
        state.couponList = action.payload?.list;
      })
      .addCase(couponVerification.fulfilled, (state, action) => {
        state.couponDetails = action.payload?.coupon;
      })
      //orders
      .addCase(getOrders.fulfilled, (state, action) => {
        if (!Array.isArray(state.getOrdersData)) {
            state.getOrdersData = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getOrdersData = [...state.getOrdersData, ...newUserList];
        state.getOrdersDataBlank = action.payload.list;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.getOrderDetails = action.payload?.order;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.addOrderDetails = action.payload?.order;
      })
      .addCase(pendingAmountPay.fulfilled, (state, action) => {
        state.pendingAmountDetails = action.payload?.order;
      })
      .addCase(varientWiseCropsSparePart.fulfilled, (state, action) => {
        state.varientWiseCropsList = action.payload?.crops;
        state.freeCrops = action.payload?.freeCrops;
        state.varientWiseSparePartList = action.payload?.spareparts;
      })
      .addCase(groupWiseCrops.fulfilled, (state, action) => {
        state.groupWiseCropsList = action.payload?.crops;
      })
      //payments
      .addCase(getPayments.fulfilled, (state, action) => {
        if (!Array.isArray(state.getPaymentsList)) {
            state.getPaymentsList = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getPaymentsList = [...state.getPaymentsList, ...newUserList];
        state.getPaymentsListBlank = action.payload.list;
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.getPaymentDetails = action.payload?.details;
      })
      //transaction 
      .addCase(getTransaction.fulfilled, (state, action) => {
        if (!Array.isArray(state.TransactionList)) {
            state.TransactionList = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.TransactionList = [...state.TransactionList, ...newUserList];
        state.TransactionListBlank = action.payload.list;
      })
      // Categories list
      .addCase(getCategories.fulfilled, (state, action) => {
        state.getCategoriesData = action.payload;
      })
      .addCase(getEnabledCategories.fulfilled, (state, action) => {
        state.getEnabledCategoriesData = action.payload;
      })
      // Crops 
      .addCase(getCrops.fulfilled, (state, action) => {
        state.getCropsList = action.payload?.list;
      })
      .addCase(getAllCrops.fulfilled, (state, action) => {
        state.getAllCropsList = action.payload?.list;
      })
      .addCase(getEnableCrops.fulfilled, (state, action) => {
        state.getEnableCropsList = action.payload?.list;
      })
      .addCase(getCropsGroups.fulfilled, (state, action) => {
        state.getCropsGroupsList = action.payload?.list;
      })
      //Varient 
      .addCase(getVarient.fulfilled, (state, action) => {
        state.getVarientList = action.payload?.list;
      })
      .addCase(getEnableVarient.fulfilled, (state, action) => {
        state.getEnableVarientList = action.payload?.list;
      })
      .addCase(getVarientWithoutPage.fulfilled, (state, action) => {
        state.getVarientWithoutPageList = action.payload?.list;
      })
      // get Spare Part 
      .addCase(getSparePart.fulfilled, (state, action) => {
        state.getSparePartList = action.payload?.list;
      })
      .addCase(getEnableSparePart.fulfilled, (state, action) => {
        state.getEnableSparePartList = action.payload?.list;
      })
      .addCase(getSparePartGroups.fulfilled, (state, action) => {
        state.getSparePartGroupsList = action.payload?.list;
      })
      .addCase(getAllSparePartGroups.fulfilled, (state, action) => {
        state.getAllSparePartGroupsList = action.payload?.list;
      })
      // Types list
      .addCase(getTypes.fulfilled, (state, action) => {
              state.getTypesData = action.payload;
        })
      .addCase(getEnabledTypes.fulfilled, (state, action) => {
              state.getEnabledTypesData = action.payload?.list;
        })
      .addCase(userSignup.fulfilled, (state, action) => {
        action.payload.status === 1 &&
          localStorage.setItem("userSignupStatus", action.payload.status);
      })
      //settings
      .addCase(getSettings.fulfilled, (state, action) => {
        state.getSettingsList = action.payload?.list;
      })
      
  }
);

export default userReducer;
