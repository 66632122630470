import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addCrops, addCropsGroup, getCrops, getCropsGroups, getEnableVarient, getVarientWithoutPage, updateCrops, updateCropsGroup } from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";
import CropsToBeThreshedAction from "./CropsToBeThreshedAction";

const CropsToBeThreshedList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const modelId = queryParameters.get("model");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getCrops({
      model:modelId
    }));
    dispatch(getCropsGroups())
  }, []);
  const { getCropsList, getCropsGroupsList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
          <Col md={12}>
            <h3>Crops</h3>
          </Col> 
          <hr/>
          <Col md={12} className="mb-2">
            <CropsToBeThreshedAction
              title="Add Crop"
              btnTitle="Add"
              api={addCrops}
              btncls="w-100"
              btncolor={"success"}
              cropSelect={false}
            />
          </Col>
          <Col md={12}>
            {
              !!getCropsList && getCropsList?.map((data, index) => <span className="m-1" key={index}>
                <CropsToBeThreshedAction
                title="Update Crop"
                btnTitle={`${data?.name}-${data?.price}/-`}
                name={data?.name}
                description={data?.description}
                status={data?.status}
                price={data?.price}
                id={data?.id}
                api={updateCrops}
                model={data?.model?.id}
                btncolor={"secondary"} 
                btncls= {"button-10"}
                cropSelect={false}
              />
              </span>)
            }
          </Col>
        </Row>
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Crops Group to be Threshed</h3>
          </Col>  
          <hr/>
          <Col md={6}>
            <CropsToBeThreshedAction
             title="Add Crops to be Threshed"
             btnTitle="Add Crop Group"
             api={addCropsGroup}
             btncolor={"success"}
             cropSelect={true} 
             btncls= {"button-10"}
             />
          </Col>
        </Row>
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Crops</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getCropsGroupsList &&
              getCropsGroupsList?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.name}
                    </td>
                    <td>{data?.crops?.map((item, itemIndex) => <Fragment key={itemIndex}><b>{item?.name} - {item?.price}/-</b><br/></Fragment>)}</td>
                    <td>{data?.description}</td>
                   <td className="table-img">
                      {data?.status === true ? <img src={green} alt="success"/> : <img src={red} alt="inactive"/>}
                    </td>
                    <td>
                      <CropsToBeThreshedAction
                        title="Update Crops to be Threshed"
                        btnTitle="Edit"
                        name={data?.name}
                        description={data?.description}
                        status={data?.status}
                        id={data?.id}
                        api={updateCropsGroup}
                        crops={data?.crops}
                        cropSelect={true}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default CropsToBeThreshedList;
