import React, { useEffect, useRef } from "react";
import "./app.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import { ToastContainer } from "react-toastify";
import NotFoundPage from "./Components/NotFoundPage";
import CategoryList from "./Pages/Category/List";
import TypeList from "./Pages/Type/List";
import ProductsList from "./Pages/Product/ProductList";
import ComplaintList from "./Pages/Complaint/ComplaintList";
import UserList from "./Pages/User/List";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Footer from "./Pages/Footer/Footers";
import { useLocation } from "react-router-dom";
import OtpSend from "./Pages/Applogin/OtpSend";
import OTPverify from "./Pages/Applogin/OTPverify";
import CreatePassword from "./Pages/Applogin/CreatePassword";
import Settings from "./Pages/Settings/Settings";
import ComplaintAdd from "./Pages/Complaint/ComplaintAdd";
import Applogin from "./Pages/Applogin/AppLogin";
import SignUp from "./Pages/SignUp.js/Signup";
import Add from "./Pages/User/Add";
import { Row } from "react-bootstrap";
import ForgotPassword from "./Pages/Applogin/ForgotPassword";
import OrderList from "./Pages/Order/OrderList";
import PaymentList from "./Pages/Payment/PaymentList";
import OrderAction from "./Pages/Order/OrderAction";
import PaymentAction from "./Pages/Payment/PaymentAction";
import SparePartsList from "./Pages/SpareParts/SparePartsList";
import CropsToBeThreshedList from "./Pages/CropsToBeThreshed/CropsToBeThreshedList";
import VarientList from "./Pages/Varients/VarientList";
import ProductAction from "./Pages/Product/ProductAction";
import UserDetail from "./Pages/User/UserDetail";
import BankDetails from "./Pages/Order/BankDetails";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Disclaimer from "./Pages/Disclaimer";
import TermsOfUse from "./Pages/TermsOfUse";
import CancellationRefundPolicy from "./Pages/CancellationRefundPolicy";
import ContactUs from "./Pages/ContactUs";
import CouponList from "./Pages/Coupon/CouponList";
import Paymentgateway from "./Paymentgateway";
import Response from "./Response";
import TransactionList from "./Pages/Transaction/TransactionList";

function App() {
  function googleTranslateElementInit() {
    new window.google.translate.TranslateElement({pageLanguage: 'en' , includedLanguages : 'en,hi'}, 'google_translate_element');
  }

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const location = useLocation();
  const width = useRef(window.innerWidth);
  const showFooter =
    // location.pathname !== "/" &&
    location.pathname !== "/dashboard" &&
    location.pathname !== "/users" &&
    location.pathname !== "/products" &&
    location.pathname !== "/categories" &&
    location.pathname !== "/posts" &&
    location.pathname !== "/orders" &&
    location.pathname !== "/login" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/sent-otp" &&
    location.pathname !== "/verify-otp" &&
    location.pathname !== "/create-new-password"
  return (
    <>
     {/* <Row className="p-0 m-0 translater-tab">
     <div id="google_translate_element"></div>
     </Row> */}
      <Routes>
        {/* web routes start*/}
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<Applogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/complaints" element={<ComplaintList />} />
        <Route path="/complaint" element={<ComplaintAdd />} />
        <Route path="/products" element={<ProductsList />} />
        <Route path="/products-action" element={<ProductAction />} />
        <Route path="/orders" element={<OrderList />} />
        <Route path="/orders-action" element={<OrderAction />} />
        <Route path="/payment" element={<PaymentList/>} />
        <Route path="/payment-action" element={<PaymentAction/>} />
        <Route path="/transaction" element={<TransactionList/>} />
        <Route path="/users" element={<UserList />} />
        <Route path="/internaluser" element={<UserList />} />
        <Route path="/user" element={<UserDetail />} />
        <Route path="/bank-details" element={<BankDetails />} />
        <Route path="/add-user" element={<Add />} />
        <Route path="/categories" element={<CategoryList />} />
        <Route path="/types" element={<TypeList />} />
        <Route path="/spare-part" element={<SparePartsList/>} />
        <Route path="/coupons" element={<CouponList/>} />
        <Route path="/varient" element={<VarientList/>} />
        <Route path="/crops-to-be-threshed" element={<CropsToBeThreshedList/>} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/sent-otp" element={<OtpSend />} />
        <Route path="/create-new-password" element={<CreatePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<OTPverify />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/cancellation-and-refund" element={<CancellationRefundPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/pay" element={<Paymentgateway />} />
        <Route path="/response/:orderId/:status" element={<Response />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* <Footer /> */}
      {/* {width.current < 668 && showFooter && <Footer />} */}
    </>
  );
}
export default App;
