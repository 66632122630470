import React, { useEffect, useRef, useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import {Col, Row, Button} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { getComplaint, getLatLogLocation, getTechnicains, resetComplaints } from "../../reducers/commonReducer";
import Update from "./ComplaintUpdate";
import { rating} from "../../const";
import Card from 'react-bootstrap/Card';
import dummy from '../../images/dummy.png'
import ViewComplaint from "./ViewComplaint";

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const assignby = queryParameters.get("assignby");
  const complaintsid = queryParameters.get("complaintsid");
  const mngroletype = queryParameters.get("mngrole");
  const productId = queryParameters.get("productId");
  const customerid = queryParameters.get("customerid");

  // useEffect(() => {
  //   dispatch(getLatLogLocation({
  //     lat:"29.0587757", 
  //     long:"76.085601"
  //   }))
  // },[])
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    // if(localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin" || localStorage.getItem("userRole") === "manager"){
    //   dispatch(getTechnicains())
    // }
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not available in this browser.");
    }
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(resetComplaints())
      dispatch(getComplaint({
        assignedBy: assignby,
        type:!!mngroletype ? mngroletype.split(',') : null,
        product : productId,
        category:complaintsid,
        customer:customerid,
        search
      }));
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getComplaintList, getComplaintListBlank, getComplaintCount } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      {/* <GetLocation/> */}
      <div className="container">
        <div className="mt-4 mb-2 overflow-auto">
          <Col md={12}>
            <h3>Complaint List ({getComplaintCount})</h3>
          </Col>
          <hr />
          <Row className="p-0 m-0">
          <Col md={4} sm={4} xs={4}>
          <label>Searching</label>
            <Form.Control
              type="text"
              placeholder="Search by ComplainId..."
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
               setSearch(e.target.value)
              }}
            />
          </Col>
          <Col md={3} sm={3} xs={3}>
            <label>StartDate</label>
            <Form.Control
              type="date"
              placeholder="Search by ComplainId..."
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
               setStartDate(e.target.value)
              }}
            />
          </Col>
          <Col md={3} sm={3} xs={3}>
          <label>EndDate</label>
            <Form.Control
              type="date"
              placeholder="Search by ComplainId..."
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
          </Col>
          <Col md={1} sm={1} xs={1}>
             <Button className="float-right mt-4" variant="success" onClick={() =>{
              dispatch(resetComplaints())
              dispatch(getComplaint({
                assignedBy: assignby,
                type:!!mngroletype ? mngroletype.split(',') : null,
                product : productId,
                category:complaintsid,
                customer:customerid,
                startDate,
                endDate
              }));
             }}>Submit</Button>
          </Col>
          <Col md={1} sm={1} xs={1}>
             <Button className="float-right mt-4" variant="danger" onClick={() => window.location.reload()}>Refresh</Button>
          </Col>
          </Row>
        </div>
        <div className="overflow-auto row mt-2">
       { !!getComplaintList
                ? getComplaintList.map((data, index) => {
                    return (
                      <Col md={4} key={index}>
                       <Card>
                        <Card.Body className={`${width.current < 668 ? "mb-2" : "mb-5"} box-shadow border ${data?.status === 3 && "bg-info"}`}>
                          <Row>
                            <Col md={2} sm={2} xs={2}>{index + 1}.</Col>
                            <Col md={8} sm={8} xs={8}><b className="text-cenater">{data?.type?.name}</b></Col>
                            <Col md={2} sm={2} xs={2}><Button className="float-right px-2 py-1 mx-1" onClick={() => window.open(`https://www.latlong.net/c/?lat=${data?.latitude}&long=${data?.longitude}`, '_blank', 'noopener,noreferrer')}><i className="bi bi-geo-alt-fill"></i></Button></Col>
                          </Row>
                          <hr/>
                          <div className="complaint-img mt-1 mb-1">
                          {data?.image?.length > 0 ? <Link to={`/${data?.image}`} target="_blank"><img src={`/${data?.image}`} alt="home"/></Link> : <img src={dummy} alt="home"/>}
                          {/* <img src={`/${data?.image}`}/> */}
                          </div>
                          {<video width="100%" height="100px" controls>
                            <source src={`/${data?.video}`} type="video/mp4"/>
                          </video>}
                          {<audio controls className="w-100">
                            <source src={`/${data?.voice}`} type="audio/mpeg" />
                            </audio>}
                         <p className="mb-0 text-center"><b>{data?.user?.name}</b></p>
                         <p className="mb-0 text-center">{data?.user?.address}, {data?.user?.city}-{data?.user?.zipcode}, {data?.user?.state}</p>
                         <p className="mb-0 text-center"><b>{data?.user?.phone}</b></p>
                         <ViewComplaint btn={"View"} btnColor={"warning"} modalsize={"lg"}
                          title={"Complaint"} type={data?.type?.name} user={data?.user} machineAddress={data?.machineAddress}
                          complainId={data?.complainId} bill={data?.bill} billNo={data?.billNo} createdAt={data?.createdAt} summery={data?.summery}
                          model={data?.model?.name} varient={data?.varient?.name} techReq={data?.techReq} technicalsupport={data?.technicalsupport}/>
                        </Card.Body>
                      </Card>
                      </Col>
                    )}) : "No record found" }
        </div>
        <Row>
          <Col>
            {!!getComplaintListBlank && getComplaintListBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getComplaint({
                     page: page + 1,
                    assignedBy: assignby,
                    type:!!mngroletype ? mngroletype.split(',') : null,
                    product : productId,
                    category:complaintsid,
                    customer:customerid
                  })
                  )
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;
