import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {  getCrops, getCropsGroups } from "../../reducers/commonReducer";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const schema = yup.object().shape({
  name: yup.string().required(),
  status: yup.string().required(),
  price: yup.number().when('cropSelect', {
    is: false,
    then: yup.number().required().positive().moreThan(0),
    otherwise: yup.number() 
  })
});
const animatedComponents = makeAnimated();
function CropsToBeThreshedAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const {
    title,
    name,
    description,
    status,
    crops,
    id,
    price,
    btnTitle,
    api,btncls,btncolor,cropSelect
  } = props;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { getCropsList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button className={btncls} variant={btncolor} onClick={handleShow}>
        {btnTitle}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(api(values));
            resetForm({ values: "" });
            dispatch(getCrops())
            dispatch(getCropsGroups())
            setShow(false)
          }}
          initialValues={{
            name,
            description,
            id,
            price,
            status,
            cropSelect,
            crops : crops ? crops?.map((item) => item?.id) : !!getCropsList && getCropsList?.map((item) => item?.id)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={8}>
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {(cropSelect === false) && <Col md={4}>
                  <Form.Group >
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.price}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
              </Row>
              {cropSelect &&  <Row className="mb-3">
                <Col md={12}>
                <Form.Label>
                  Crops
                </Form.Label>
                  <Select
                        className="border"
                        value = {
                            !!getCropsList &&
                            getCropsList?.map((data) => {
                              if (values?.crops?.indexOf(data?.id) != -1) {
                                return {
                                  value: data?.id,
                                  label: data?.name,
                                };
                              }
                            })
                          }
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            `crops`,
                            selectedOptions?.map((data) => data?.value)
                          );
                        }}
                        options={
                        !!getCropsList &&
                        getCropsList.map((data) => {
                        return {
                                value: data?.id,
                                label: data?.name,
                              };
                            })
                          }
                        />
                </Col>
              </Row>
              }
              <Row className="mb-3">
                <Col md={12} sm={12}>
                <Form.Group >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                      as="textarea" rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default CropsToBeThreshedAction;
