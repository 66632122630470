import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addCouponCode, getCouponList,  getVarientWithoutPage,  updateCoupon,  updateSparePartGroup } from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";
import SparePartsAction from "./CouponAction";
import CouponAction from "./CouponAction";

const CouponList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getCouponList())
    dispatch(getVarientWithoutPage())
  }, []);
  const {  couponList, } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Coupons</h3>
          </Col>  
          <hr/>
          <Col md={6}>
            <SparePartsAction
             title="Add Coupon Code"
             btnTitle="Add Coupon Code"
             api={addCouponCode}
             btncolor={"success"}
             sparepartSelect={true}
             />
          </Col>
        </Row>
        <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Price</th>
              <th>Varient's</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!couponList &&
              couponList?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.name}
                    </td>
                    <td>
                      {data?.price}
                    </td>
                    <td>{
                      data?.varient?.map((varient, varientIndex) => <Fragment key={varientIndex}><b>{varient?.name} - {varient?.price}/-</b><br/></Fragment>)
                      }
                    </td>
                    <td className="table-img">
                      {data?.status === true ? <img src={green} alt="success"/> : <img src={red} alt="inactive"/>}
                    </td>
                    <td>
                      <CouponAction
                        title="Update Coupon Code"
                        btnTitle="Edit"
                        name={data?.name}
                        price={data?.price}
                        status={data?.status}
                        varient={data?.varient}
                        id={data?.id}
                        api={updateCoupon}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default CouponList;
