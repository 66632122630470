import Carousel from 'react-bootstrap/Carousel';

function VarientImg({varientImages}) {
  const varientImg = varientImages.split(','); 
  return (
    <Carousel fade>
      
        {
          !!varientImg && varientImg?.map((img, index) => <Carousel.Item>
          <img src={`/${img}`}/>
           </Carousel.Item>) 
        }
    
    </Carousel>
  );
}

export default VarientImg;