import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import {Row, Button} from "react-bootstrap";
import {  getTransaction, resetTransaction } from "../../reducers/commonReducer";
import moment from "moment";

const TransactionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(resetTransaction());
    let timeOut = setTimeout(() => {
      dispatch(getTransaction({
        dealer:userId,
        search
      }));
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { TransactionList, TransactionListBlank } = useSelector((state) => state.commonReducer);


  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Transaction List</h3>
          </Col>
          <hr />
        </Row>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Date</th>
                <th>Pay</th>
                {/* <th>Sub</th> */}
                <th>Remaining</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {!!TransactionList
                ? TransactionList?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{moment(data?.createdAt).format("LLL")}</td>
                        <td>{(data?.type === "ADD") && <b className="text-success">{data?.amount}/-</b>}</td>
                        {/* <td>{(data?.type === "SUBTRACT") && <b className="text-danger">{data?.amount}/-</b>}</td> */}
                        {(index === 0) ? <td className="bg-danger text-white"><b>-{data?.remaining}/-</b></td> :
                        <td><b>{data?.remaining}/-</b></td>}
                        <td>{data?.comment}</td>
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
        <Row>
          <Col>
            {!!TransactionListBlank && TransactionListBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getTransaction({
                      dealer:userId,
                      page: page + 1,
                      search
                    })
                  );
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TransactionList;
