import React from "react";
import logo from "../images/logo.jpg";
import { Col, Container, Row } from "react-bootstrap";

const ContactUs = () => {
  return (
    <Container>
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
            <h1><b>Contact Us</b></h1>
            <hr/>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6}>
            <p className="mb-0"><b>Address</b></p>
            <p>CJXW+8PV, road, near Under Bridge, Ellenabad, Haryana 125102</p>
            <p className="mb-0"><b>Contact Details</b></p>
            <p className="mb-0">9991571944</p>
            <p>support@nvtthresher.com</p>
        </Col>
        <Col md={6}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27797.930145362458!2d74.61955884049102!3d29.43635751503459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3916bcd20cbb6ca9%3A0xf9442d14010fd3a5!2sNew%20Vishwakarma%20Agriculture%20Engineering%20Works!5e0!3m2!1sen!2sin!4v1711623361076!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
