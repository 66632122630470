import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import Header from "../../Components/Header";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {  addUser, getEnabledTypes } from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email("Invalid email address").required(),
  address: yup.string().required("Please Enter Address."),
  city: yup.string().required("Please Enter city."),
  state: yup.string().required("Please Enter state."),
  phone: yup.string().min(10).max(10).required("Please Enter Phone number."),
  status: yup.string().required(""),
  role:yup.string().required("Please Assign User Role"),
  password: yup.string().required("Password is required."),
  type: yup.array().when('role', {
    is: 'manager',
    then: yup.array().required('Manager type is required'),
  }),
});

function Add() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getEnabledTypes())
  },[])
  const api = async (values) => {
    const apiResponse = await dispatch(addUser(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/internaluser");
      }, 1000);
    }
    return null;
  };
  const { getEnabledTypesData } = useSelector((state) => state.commonReducer);
  return (
    <>
    <Header/>
    <div className="container mt-4">
    <Formik
        validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
             dispatch(api(values));
            resetForm({ values: "" });
          }}
          initialValues={{
            name: '',
            address: '',
            city: '',
            state: '',
            phone: '',
            email: '',
            status: true,
            password:'',
            role:'',
            type:[],
            firmname:'',
            gst:'',
            gstNo:'',
            msme:'',
            aadhar:'',
            bank:'',
            secondPhone:''
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="city"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={4}>
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("role", e.target.value);
                    }}
                    name="role"
                    className="form-control"
                    value={values.role}
                    isInvalid={!!errors.role}
                  >
                    <option value={''} >Select Role</option>
                    {/* <option value={"dealer"} >Dealer</option> */}
                    {localStorage.getItem("userRole") !== "manager" && <option value={"manager"}>Manager</option>}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.role}
                    </Form.Control.Feedback>
                </Col>
                {
                  values.role === "manager" && <Col md={4}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="type"
                    multiple={true}
                    className="form-control"
                    value={values.type}
                    isInvalid={!!errors.type}
                  >
                    {
                      !!getEnabledTypesData && getEnabledTypesData?.map((data, index) => {
                        return <option value={data?.id} key={index}>
                        {data?.name}
                        </option>
                      })
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                </Col>
                }
                 <Col md={4}>
                  <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
              </Row>
              {values.role === "dealer" && <><Row className="mb-3">
                    <Col md={3} sm={12}>
                        <Form.Group>
                          <Form.Label>FirmName</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Firm Name"
                              name="firmname"
                              value={values.firmname}
                              onChange={handleChange}
                              isInvalid={!!errors.firmname}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firmname}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                      <Form.Group as={Col} md="12">
                        <Form.Label>GST</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="gst"
                          onChange={(event) => {
                            const gstImg = event.target.files[0];
                            setFieldValue("gst", gstImg);
                          }}
                          isInvalid={!!errors.gst}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.gst}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group>
                          <Form.Label>GST No</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter GST No."
                              name="gstNo"
                              value={values.gstNo}
                              onChange={handleChange}
                              isInvalid={!!errors.gstNo}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.gstNo}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group>
                          <Form.Label>Second Phone</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Second Phone"
                              name="secondPhone"
                              value={values.secondPhone}
                              onChange={handleChange}
                              isInvalid={!!errors.secondPhone}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.secondPhone}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4} sm={12}>
                          <Form.Group>
                            <Form.Label>MSME</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter MSME"
                                name="msme"
                                value={values.msme}
                                onChange={handleChange}
                                isInvalid={!!errors.msme}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.msme}
                              </Form.Control.Feedback>
                          </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                          <Form.Group>
                            <Form.Label>Aadhar Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Aadhar Number"
                                name="aadhar"
                                value={values.aadhar}
                                onChange={handleChange}
                                isInvalid={!!errors.aadhar}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.aadhar}
                              </Form.Control.Feedback>
                          </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                          <Form.Group>
                            <Form.Label>Bank Details</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Write Bank Details..."
                                as="textarea"
                                row={3}
                                name="bank"
                                value={values.bank}
                                onChange={handleChange}
                                isInvalid={!!errors.bank}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.bank}
                              </Form.Control.Feedback>
                          </Form.Group>
                    </Col>
                  </Row>
                  </>
                }
              <Modal.Footer>
                <Button variant="success" className="w-100" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
        </div>
    </>
  );
}

export default Add;
