import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import Form from "react-bootstrap/Form";
import {  getOrders, resetOrders } from "../../reducers/commonReducer";
import { Button } from "react-bootstrap";
import OrderPaymentOption from "./OrderPaymentOption";

const OrderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
 
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(resetOrders());
    let timeOut = setTimeout(() => {
    dispatch(getOrders({
        userid:userId,search
      }))
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getOrdersData, getOrdersDataBlank } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Orders List</h3>
          </Col>
          <hr /> 
          <Col className="text-end" md={6}> 
            <Form.Control
              type="search"
              placeholder="Search by FirmName, City, State"
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </Col>
        </Row>

        <div className="overflow-auto table-container">
          <Table striped bordered hover size="sm" className=" overflow-scroll">
            <thead>
              <tr>
                <th>OrderID</th>
                <th>Date</th>
                {localStorage.getItem("userRole") !== "dealer" && <th>DealerName</th>}
                <th>Model</th>
                <th>Varient</th>
                <th>FreeCrops</th>
                <th>PaidCrops</th>
                <th>SparePart</th>
                <th>Other Requirement</th>
                <th>Coupon</th>
                <th className="w-20">Amount</th>
                <th>Qty</th>
                <th>Tractor Model</th>
                <th>Expected Date</th>
                <th>Status</th>
                <th>Payment Status</th>
                {localStorage.getItem("userRole") !== "dealer" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {!!getOrdersData
                ? getOrdersData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data?.orderid}<br/>
                        {((data?.payAmount !== data?.amount) && (localStorage.getItem("userRole") === "dealer")) && <OrderPaymentOption btntitle={`Pay`} 
                        amount={data?.amount} 
                        payAmount={data?.payAmount}
                        orderid={data?.id}/>}
                        </td>
                        <td>{moment(data?.createdAt).format("LL")}</td>
                        {localStorage.getItem("userRole") !== "dealer" &&
                        <td>{data?.dealer?.name} ({data?.dealer?.username})</td>}
                        <td>{data?.model?.name}</td>
                        <td>{data?.varient?.name}</td>
                        <td>{data?.freeCropList?.map((crops, freeIndex) => <Fragment key={freeIndex}><span>{crops?.name}, </span><br/></Fragment>)}</td>
                        <td>{data?.crops?.map((crops , cropindex) => <Fragment key={cropindex}><span>{crops?.name}, </span><br/></Fragment>)}</td>
                        <td>{data?.spareparts?.map((item, itemIndex) => <Fragment key={itemIndex}><span>{item?.name}, </span><br/></Fragment>)}</td>
                        <td>{data?.other}</td>
                        <td>{data?.couponcode}</td>
                        <td>
                          {
                            (data?.paymentStatus === "offline") ? <span className="text-info"><b>Direct Bank</b></span> : <>
                             <span className="bg-primary d-flex text-white">TotalAmount: <b>{data?.amount}/-</b></span>
                          <span className="bg-success d-flex text-white"> PayAmount: <b>{data?.payAmount}/-</b></span>
                          <span className="bg-danger d-flex text-white">Pending: <b>{data?.amount - data?.payAmount}/-</b></span>
                            </>
                          }
                        </td>
                        <td>{data?.quantity}</td>
                        <td>{data?.tractorModel}</td>
                        <td>{data?.deliveredDate && moment(data?.deliveredDate).format("LL")}</td>
                        <td>
                          <b>{
                              data?.status === 0 && <span className="text-primary">Pending</span> ||
                              data?.status === 1 && <span className="text-danger">Cancel</span> ||
                              data?.status === 2 && <span className="text-info">Processing</span> ||
                              data?.status === 3 && <span className="text-warning">Shipping</span> ||
                              data?.status === 4 && <span className="text-success">Completed</span>
                            }</b>
                        </td>
                        <td>
                          <b>
                          {data?.paymentStatus === "Success" ? 
                          <span className="text-success">{data?.paymentStatus}</span> : 
                          <span className="text-primary">{data?.paymentStatus}</span>}</b>
                        </td>
                        {localStorage.getItem("userRole") !== "dealer" &&
                        <td>
                        {(data?.status !== 4 && (localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin" || localStorage.getItem("userRole") === "manager")) && 
                        <Link to={`/orders-action?orderId=${data?.id}`}>
                          <Button>Update</Button>
                        </Link>
                        }
                        {(data?.status === 0 && (localStorage.getItem("userRole") === "dealer")) && 
                        <Link to={`/orders-action?orderId=${data?.id}`}>
                          <Button>Update</Button>
                        </Link>
                        }
                        </td>}
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
        <Row>
          <Col>
            {!!getOrdersDataBlank && getOrdersDataBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getOrders({
                      page: page + 1,
                      userid:userId,search
                    })
                  );
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrderList;
