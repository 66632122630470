import React, { useCallback, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Webcam from "react-webcam";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
const videoConstraints = {
  facingMode: FACING_MODE_USER
};

export default function VideoRecorder({recordedChunks, setRecordedChunks}) {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [camfacingMode, setCamFacingMode] = React.useState(FACING_MODE_USER);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );
  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const flipCamera = React.useCallback(() => {
    setCamFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);
  return (
    <div className="Container">
      <Webcam
        width={'100%'}
        audio={false}
        // mirrored={true}
        ref={webcamRef}
        videoConstraints={{
          ...videoConstraints,
          facingMode : camfacingMode
        }}
      />
      {recordedChunks?.length > 0 && <p className="text-success mb-0"><b>Video alredy is recorded.</b></p>}
      <Row className="text-center">
        <Col md={6} xs={6} sm={6}>
        {capturing ? (
        <Button variant="danger" onClick={handleStopCaptureClick}>Video Stop</Button>
      ) : (
        <Button variant="success" onClick={handleStartCaptureClick}>Video Start</Button>
      )}
        </Col>
        <Col md={6} xs={6} sm={6}>
        <Button onClick={flipCamera}>Rotate Camera</Button>
        </Col>
      </Row>
    </div>
  );
}