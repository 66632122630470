import React from "react";
import Header from "../../Components/Header";
import { Row,Col, Button } from "react-bootstrap";
import imgborder from "../../images/border.png"
import { Link } from "react-router-dom";

const BankDetails = () => {
  return (
    <>
    <Header/>
      <div className="container mt-4">
        <Row>
            <Col className="box-shadow mb-3 p-4">
            <div className="text-center mb-4">
            <h3 className="mb-0">Bank Details</h3>
            <img src={imgborder} alt="home"/>
            </div>
            <p>Account Name: <b>NEW VISHWAKARMA AGRI. ENGG. WORKS</b></p>
            <p>A/C NO.: <b>55057613843</b></p>
            <p>IFSC CODE: <b>SBIN0016833</b></p>
            <p>Branch: <b>Ellenabad State Bank of India</b></p>
            <Link to="/payment-action">
              <Button variant="info">
                Upload Payment Details
              </Button>
            </Link>
            </Col>
        </Row>
      </div>
    </>
  );
};

export default BankDetails;
