import React, {useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Update from "./Update";
import Form from "react-bootstrap/Form";
import {
  getUsers,getEnabledTypes, resetUsers, userUpdate
} from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";
 
const List = () => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(resetUsers())
    dispatch(getEnabledTypes())
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
    dispatch(resetUsers())
    dispatch(getUsers({
      search,
      role: location.pathname === "/internaluser" ? "manager" : "dealer"
    }));
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getUsersData, getUsersDataBlank, userCount } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6">
            <h3>{location.pathname === "/internaluser" ? "Internal Users" : "Dealers"} ({userCount})</h3>
          </div>
          {
            ((localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && location.pathname === "/internaluser") && <div className="col-md-6 text-end col-6">
            <Button
              className="mb-3 mobile-width submit-buttom"
              onClick={() => navigate("/add-user")}
            >
              Add Manager
            </Button>
          </div>
          }
          
          <div className="col-md-12 p-0">
                  <Row className=" mb-3 border pt-3 pb-3 justify-content-between">
                    <Col md={4} sm={12}>
                      <Form.Label>Name, City, State</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        name="name"
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                    </Col>
                    <Col md={2}>
                      <Button className="mt-4 float-right" variant="danger" onClick={() => window.location.reload()}>Refresh</Button>
                      </Col>
                  </Row>
                
          </div>
          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>{location.pathname === "/internaluser" ? "Name" : "FirmName"}</th>
                  {/* <th>PendingAmount</th> */}
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!!getUsersData &&
                  getUsersData.map((data, index) => {
                    return (
                      <tr key={data?.id}>
                        <td>{index + 1}.</td>
                        <td>
                          {location.pathname === "/internaluser" ? data?.name : data?.firmname}<br/>
                          <span className="text-danger">Pending Amount -{data?.balance} Rs.</span>
                          {data?.role?.slug === "dealer" && <>
                          <Link to={`/orders?userid=${data?.id}`}><Button variant="success" className="m-1 button-9">View Order</Button></Link><br/>
                          <Link to={`/payment?userid=${data?.id}`}><Button variant="warning" className="m-1 button-9">View Payments</Button></Link><br/>
                          <Link to={`/transaction?userid=${data?.id}`}><Button variant="info" className="m-1 button-9">View Transaction</Button></Link>
                          </>}
                        </td>
                        {/* <td>{data?.balance}/-</td> */}
                        <td>{data?.phone}</td>
                        <td>{data?.email}</td>
                        <td>{`${data?.address}, ${data?.city}, ${data?.state}`}</td>
                        <td onClick={async() => {
                          const modalStatus = window.confirm("Are you sure for update user status.");
                          if(modalStatus){
                            await dispatch(userUpdate({
                              id:data?.id,
                              status: !data?.status
                            }))
                           await dispatch(resetUsers())
                           await dispatch(getUsers({
                            role: location.pathname === "/internaluser" ? "manager" : "dealer"
                           }))
                          }
                        }} className="table-img">
                          {data?.status === true ? (
                            <img src={green} alt="active"/>
                          ) : (
                            <img src={red} alt="inactive"/>
                          )}
                        </td>
                        <td>
                          {
                            (data?.role?.slug === "dealer") && <Link to={`/user?userid=${data?.id}`}>
                            <Button variant="warning button-9 m-2">View Details</Button>
                          </Link>
                          }
                          
                          {
                            data?.role?.slug === "dealer" ? <Link to={`/signup?userid=${data?.id}`}><Button><i className="bi bi-pencil-square"></i></Button></Link> : <Update
                            fName={data?.name}
                            userAdrs={data?.address}
                            userCity={data?.city}
                            userState={data?.state}
                            userPhone={data?.phone}
                            userEmail={data?.email}
                            status={data?.status}
                            id={data?.id}
                            userRole={data?.role?.slug}
                            userType={data?.type}
                            firmname={data?.firmname}
                            gst={data?.gst}
                            gstNo={data?.gstNo}
                            msme={data?.msme}
                            aadhar={data?.aadhar}
                            bank={data?.bank}
                            secondPhone={data?.secondPhone}
                          />
                          }
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        <Row>
          <Col>
            {!!getUsersDataBlank && getUsersDataBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getUsers({
                      page: page + 1,
                      role: location.pathname === "/internaluser" ? "manager" : "dealer"
                    })
                  );
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;
