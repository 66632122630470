import { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { pendingAmountPay } from '../../reducers/commonReducer';

function OrderPaymentOption(props) {
  const [show, setShow] = useState(false);
  const [payPercentage, setPayPercentage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
const {btntitle, payAmount, amount, orderid} = props;
  return (
    <>
      <Button variant="success" className='button-9' onClick={handleShow}>
        {btntitle}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Pending Payment</Modal.Title>
        </Modal.Header>
        <hr className='m-0'/>
        <Modal.Body>
              <Row>
                    <Col md={12}>
                      <Form.Label className='text-danger'>Amount Pay Percentage*</Form.Label>
                      <InputGroup>
                      <InputGroup.Text>@</InputGroup.Text>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setPayPercentage(e.target.value);
                        }}
                        name="payPercentage"
                        className="form-control"
                        value={payPercentage}
                      >
                        <option value="">Select pay Percentage</option>
                        <option value={"10"}>10%</option>
                        <option value={"20"}>20%</option>
                        <option value={"30"}>30%</option>
                        <option value={"40"}>40%</option>
                        <option value={"50"}>50%</option>
                        <option value={"60"}>60%</option>
                        <option value={"70"}>70%</option>
                        <option value={"80"}>80%</option>
                        <option value={"90"}>90%</option>
                        <option value={"100"}>100%</option>
                      </Form.Control>
                      </InputGroup>
                      {!!payPercentage && <h3 className='text-success mt-3'><b>{(payPercentage === "100") ? (amount - payAmount) : ((amount - payAmount) * `0.${payPercentage}`)}/-</b></h3>}
                    </Col>
              </Row>
              {
                payPercentage?.length > 0 && <Row className='pt-5 pb-5'>
                <Col>
                <Button className='w-100' variant="warning"
                onClick={async() => {
                 const result = await dispatch(pendingAmountPay({
                      orderid,
                      payPercentage: +payPercentage
                  }))
                  if(result.payload?.status){
                    return setTimeout(() => {
                      navigate(`/pay`); 
                    }, 1000);
                  }
                }}
                >{(payPercentage === "100") ? (amount - payAmount) : ((amount - payAmount) * `0.${payPercentage}`)} /- Pay (2% Payment Gateway charges apply on payment time).</Button>
                </Col>
                {/* <Col>
                <Link to={`/bank-details`}>
                <Button className='w-100' variant="info"><i className="bi bi-bank"></i> Direct Bank</Button>
                </Link>
                </Col> */}
            </Row>
              }
            {/* <Row className='pb-5'>
                <Col>
                <p className='mb-0 text-danger text-center'><b>Using Payment Gateway</b></p>
                <hr className='m-1'/>
                <h5 className='text-danger'><b>Note:</b> All Payment Gateway charges are applied to the dealer. </h5>
                </Col>
            </Row> */}
        </Modal.Body>
        <hr className='m-0'/>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderPaymentOption;