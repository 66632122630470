import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {  getVarient, groupWiseCrops, getVarientWithoutPage } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  status: yup.string().required(),
  price:yup.number().required(),
  crop: yup.string().required(),
  sparepart: yup.string().required(),
});

function VarientAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const {
    title,
    name,
    description,
    status,freeCrops,
    id,image,sparepart,crop,
    btnTitle,btnwdth,
    api,price
  } = props;
  const handleClose = () => setShow(false);
  const { getAllSparePartGroupsList, getAllCropsList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button className={btnwdth} variant={btnTitle === "Add" ? "success" : "primary"} onClick={() => {
         dispatch(groupWiseCrops({id: crop}));
         setShow(true)
      }}>
        {btnTitle}
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            console.log("values", values)
            await dispatch(api(values));
            resetForm({ values: "" });
            dispatch(getVarient())
            dispatch(getVarientWithoutPage())
            setShow(false)
          }}
          initialValues={{
            name,
            description,
            id,
            image, price,
            status, sparepart, crop, freeCrops
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.price}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Spare Part</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("sparepart", e.target.value);
                    }}
                    name="sparepart"
                    className="form-control"
                    value={values.sparepart}
                    isInvalid={!!errors.sparepart}
                  > 
                    <option value="">Select SpareParts</option>
                    {
                      !!getAllSparePartGroupsList && getAllSparePartGroupsList?.map((item, index) => <option key={index} value={item?.id}>{item?.name}</option>)
                    }
                  </Form.Control>
                </Col>
                <Col md={6}>
                  <Form.Label>Crops Threshed</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("crop", e.target.value);
                    }}
                    name="crop"
                    className="form-control"
                    value={values.crop}
                    isInvalid={!!errors.crop}
                  > 
                    <option value="">Select Crops</option>
                    {
                      !!getAllCropsList && getAllCropsList?.map((item, index) => <option key={index} value={item?.id}>{item?.name}</option>)
                    }
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                <Form.Group >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Write Description..."
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                      as="textarea" rows={4}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={4}>
                  <Form.Group >
                    <Form.Label>Free Crops</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter FreeCrops"
                      name="freeCrops"
                      value={values.freeCrops}
                      onChange={handleChange}
                      isInvalid={!!errors.freeCrops}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.freeCrops}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group as={Col} md="12">
                      <Form.Label>Varient Image</Form.Label>
                      <Form.Control
                        id="variantImgs"
                        type="file"
                        multiple  
                        accept="image/*"
                        name="image"
                        onChange={(event) => {
                          const variantImgs = event.target.files;
                          setFieldValue("image", variantImgs);
                        }}
                        isInvalid={!!errors.image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                <Col md={4}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  > 
                    <option value="">Select Status</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default VarientAction;
