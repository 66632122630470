import moment from 'moment';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ViewComplaint({btn, summery, title, btnColor, techReq, technicalsupport, varient, model, modalsize,type,user,machineAddress,complainId,bill,billNo,createdAt}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className='w-100 mt-2' variant={btnColor} onClick={handleShow}>
        {btn}
      </Button>

      <Modal show={show} size={modalsize} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} ({complainId})</Modal.Title>
        </Modal.Header>
        <hr className='m-0'/>
        <Modal.Body>
            <p className='m-0'><b>Type:</b> {type}</p>
            <p className='m-0'><b>Varient:</b> {varient}</p>
            <p className='m-0'><b>Model:</b> {model}</p>
            <p className='m-0'>{moment(createdAt).format("LL")}</p>
            <p className='m-0'><b>Technical Support:</b> {techReq ? "Yes" : "No"}</p>
            <p className='m-0'><b>Technical Req:</b> {technicalsupport ? "Yes" : "No"}</p>
            <hr className='m-0'/>
            <h4 className='m-0'>Dealer Detail</h4>
            <p className='m-0'><b>Name:</b> {user?.name}</p>
            <p className='m-0'><b>Phone:</b> {user?.phone}</p>
            <p className='m-0'><b>Address:</b> {user?.address}, {user?.city}-{user?.zipcode}, {user?.state}</p>
            <hr/>
            <img className='w-100' src={bill} alt="Bill"/>
            <p className='m-0'><b>Bill No.</b>{billNo}</p>
            <p className='m-0'><b>Machine Address:</b> {machineAddress}</p>
            <p className='m-0'><b>{summery}</b></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className='w-100' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewComplaint;