import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getPayments, resetPayment, updatePayment } from "../../reducers/commonReducer";
import ImgModal from '../ImgModal';
import moment from "moment";

const PaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(resetPayment())
    let timeOut = setTimeout(() => {
      dispatch(getPayments({
        dealer:userId,
        search
      }));
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getPaymentsList, getPaymentsListBlank } = useSelector((state) => state.commonReducer);
  const [page, setPage] = useState(1);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Payment List</h3>
          </Col>
          <hr />
          {
            (localStorage.getItem("userRole") === "dealer") &&  <Col md={6}>
            <Link to="/payment-action"><Button>Add Payment</Button></Link>
          </Col>
          }
         
          <Col className="text-end" md={6}>
            <Form.Control
              type="search"
              placeholder="Search by Transaction Id"
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </Col>
        </Row>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Date</th>
                <th>Dealer Name</th>
                <th>Phone</th>
                <th>Amount</th>
                <th>Transaction Id</th>
                <th>Paymentgateway/Bank</th>
                <th>Mode</th>
                <th>Image</th>
                <th>Status</th>
                <th>Review</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {!!getPaymentsList
                ? getPaymentsList?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{moment(data?.createdAt).format("LLL")}</td>
                        <td>{data?.dealer?.name}</td>
                        <td>{data?.dealer?.phone}</td>
                        <td>{data?.amount}/-</td>
                        <td>{data?.transactionid}</td>
                        <td>
                          <span className={(data?.paymentType === "offline") ? `text-success` : `text-danger`}>
                            <b>{(data?.paymentType === "offline") ? `Bank` : data?.paymentType}</b>
                          </span>
                        </td>
                        <td>{data?.mode}</td>
                        <td><ImgModal btn={"View"} img={data?.image} title="Payment" btnColor={"warning"} modalsize={"lg"}/></td>
                        <td 
                        onClick={((data?.status === 0) && (data?.paymentType === "offline") && ((localStorage.getItem("userRole") === "superadmin") || (localStorage.getItem("userRole") === "admin"))) ? async() => {
                          const modalStatus = window.confirm("Are you sure for update payment status.");
                          if(modalStatus){
                            const apiResult = await dispatch(updatePayment({
                              id:data?.id,
                              status: data?.status === 0 ? 1 : 0
                            }))
                            if(apiResult?.payload?.status){
                              dispatch(resetPayment())
                              dispatch(getPayments())
                            }
                          }
                        } : null}
                        >{data?.status === 0 ? <Button className="button-9" variant="info">Pending</Button> : <Button className="button-9" variant="success">Verify</Button>}</td>
                        <td>{data?.comment}</td>
                        {/* <td>
                          {
                          (data?.status === 0 && localStorage.getItem("userRole") === "dealer") && (
                                <Link to={`/payment-action?paymentid=${data?.id}`}>
                                  <Button><i className="bi bi-pen-fill"></i></Button>
                                </Link>
                              ) || ((data?.status === 0 || data?.status === 1) && ((localStorage.getItem("userRole") === "admin") || (localStorage.getItem("userRole") === "manager"))) && (
                                <Link to={`/payment-action?paymentid=${data?.id}`}>
                                <Button><i className="bi bi-pen-fill"></i></Button>
                              </Link>
                              )
                            }
                        </td> */}
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
        <Row>
          <Col>
            {!!getPaymentsListBlank && getPaymentsListBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getPayments({
                      page: page + 1,
                      dealer:userId,
                      search
                    })
                  );
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PaymentList;
