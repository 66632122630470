import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import logo from "./images/logo.png"


const Response = () => {
  let params = useParams();

  return (
        <Container>
          <Row className="height-100vh text-center align-center">
            <img className="w-auto m-auto" src={logo} alt="logo"/>
            {
              (params?.status === "0") &&  <h1 className="text-danger">
              <b>Your payment has not been completed. Please try again.</b>
              </h1>
            }
           {
              (params?.status === "1") &&  <h1 className="text-success">
              <b>Your payment has been successfully processed.</b>
              </h1>
            }
            <Link to="/orders">
              <Button>
                Back to Order's
              </Button>
            </Link>
          </Row>
        </Container>
  );
};

export default Response;
