import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Button } from 'react-bootstrap';

const VoiceRecorder = ({setAudioRecord, audioRecord}) => {
  const recorderControls = useAudioRecorder()
  const addAudioElement = (blob) => {
    var reader = new window.FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
      var base64 = reader.result;
       setAudioRecord(base64);
    }
  };
  return (
    <div>
      <AudioRecorder 
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
      />
      {audioRecord && <p className="text-success mb-0"><b>Audio alredy is recorded.</b></p>}
      <Button variant="danger" onClick={recorderControls.stopRecording}>Stop Recording</Button>
    </div>
  )
}
export default VoiceRecorder;