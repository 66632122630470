import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addVarient, getAllCrops, getAllSparePartGroups, getVarient, updateVarient } from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";
import VarientAction from "./VarientAction";

const VarientList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getVarient());
    dispatch(getAllSparePartGroups())
    dispatch(getAllCrops())
  }, [dispatch, navigate]);
  const { getVarientList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Varient</h3>
          </Col>  
          <hr/>
          <Col md={6}>
            <VarientAction
             title="Add Varient"
             btnTitle="Add"
             api={addVarient}
             />
          </Col>
        </Row>
          
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Price(Inc. GST)</th>
              <th>Spare Parts</th>
              <th>Crops</th>
              <th>Free Crops</th>
              <th>Description</th>
              <th>Img</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getVarientList &&
              getVarientList.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>{data?.name}</td>
                    <td>{data?.price && `${data?.price}/-`}</td>
                    <td>{data?.sparepart?.name}</td>
                    <td>{data?.crop?.name}</td>
                    <td>{data?.freeCrops}</td>
                    <td>{data?.description}</td>
                    <td>
                      {
                        data?.image?.map((img, imgIndex) =>  <img key={imgIndex} className="max-width-30" alt="NVT" src={`/${img}`}/>)
                      }
                    </td>
                    <td className="table-img"
                      onClick={async() => {
                        const modalStatus = window.confirm("Are you sure for update Varient status.");
                        if(modalStatus){
                          await dispatch(updateVarient({
                            id:data?.id,
                            status: !data?.status
                          }))
                        await dispatch(getVarient())
                        }
                      }}>
                        {data?.status === true ? <img src={green} alt="NVT"/> : <img src={red} alt="NVT"/>}
                      </td>
                    <td>
                      <VarientAction
                        title="Update Varient"
                        btnTitle="Edit"
                        name={data?.name}
                        description={data?.description}
                        status={data?.status}
                        sparepart={data?.sparepart?.id}
                        crop={data?.crop?.id}
                        id={data?.id}
                        price={data?.price}
                        api={updateVarient}
                        freeCrops={data?.freeCrops}
                        image={data?.image}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default VarientList;
