import React from "react";
import logo from "../images/logo.jpg";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container>
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
            <h1><b>Privacy Policy</b></h1>
           <h4>Our Commitment</h4>
           <p>b2b.nvtthresher.in is strongly committed to your right to privacy and to keeping your personal and financial information secure. To earn your trust and confidence in our commitment, we are fully disclosing our privacy practices. We encourage you to read our privacy statement to understand what types of personally identifiable information we collect and how we use this information.</p>
           <h4>Information collected by b2b.nvtthresher.in and how it is used</h4>
           <p>b2b.nvtthresher.in only collects the necessary personally identifiable information in order to respond to your requests for our customized products and services. Personally identifiable information includes, but is not limited to, first and last name, physical address, e-mail address, phone number, birth date, and any other information that itself identifies or when tied to the above information, may identify you as a specific individual. This website does not collect personally identifiable information about you except when you specifically and knowingly provide it. b2b.nvtthresher.in and its service partners use your personally identifiable information to operate the sites and services and to inform you of new features, services, and products from b2b.nvtthresher.in and its affiliates.</p>
           <p>b2b.nvtthresher.in may also carefully select other companies to send you information about their products or services which are related to the site's service but are not necessary to its operation (an "Extended Service"). If b2b.nvtthresher.in intends to use personally identifiable information for an Extended Service, the b2b.nvtthresher.in site will provide you with the ability and requisite instructions to decline this service. You may also stop the delivery of informational or promotional mail from b2b.nvtthresher.in by following the instructions on the mail.</p>
           <p>b2b.nvtthresher.in may disclose personally identifiable information if required to do so by law or in the good-faith belief that such action is necessary to (i) conform to the edicts of the law or comply with legal process served on the site or its owners; (ii) protect and defend the rights or property of the site-owners, the site or the users of b2b.nvtthresher.in, and (iii) act under exigent circumstances to protect the personal safety of users of b2b.nvtthresher.in, the site, its owners, or the public.</p>
           <h4>User/member interaction features</h4>
           <p>b2b.nvtthresher.in has many features that provide for users/members to disclose their identities and communicate with other users/members. Your use of such features and disclosure of any personal details (e.g. e-mail address) by you through these features are at your sole risk and b2b.nvtthresher.in accepts no responsibility for the same. However, if your privacy has been intruded as a result of participating in such features, you could notify us by e-mail at support@nvtthresher.com. Other Websites</p>
           <p>The b2b.nvtthresher.in site contains links to other websites. b2b.nvtthresher.in is not responsible for the privacy practices or the content of such websites. Notify Us If at any time you believe that b2b.nvtthresher.in or its users/members have not adhered to these principles, please notify b2b.nvtthresher.in by e-mail at support@nvtthresher.com and we will use all commercially reasonable efforts to promptly determine and correct the problem.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
