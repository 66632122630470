import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Header from "../../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, addVarient, getAllCrops, getAllSparePartGroups, getProducts, getVarientWithoutPage, singleProduct, updateProduct } from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import VarientAction from "../Varients/VarientAction";


const schema = yup.object().shape({
  name: yup.string().required(),
});
const animatedComponents = makeAnimated();
function ProductAction(props) {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const productId = queryParameters.get("pid");
  const navigate = useNavigate();
  useEffect(() => {
    if(productId){
      dispatch(singleProduct({
        id:productId
      }))
    }
    dispatch(getVarientWithoutPage())
    dispatch(getAllSparePartGroups())
    dispatch(getAllCrops())
  },[])
  const { getVarientWithoutPageList, singleProductData } = useSelector((state) => state.commonReducer);
  return (
    <>
    <Header/>
        <Formik
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const result = await dispatch(productId ? updateProduct(values) : addProduct(values));
            if(result?.payload?.status){
              setTimeout(() => {
                navigate(`/products`)
              }, 1000)
            }
            resetForm({ values: "" });
            dispatch(getProducts())
          }}
          initialValues={{
            name : singleProductData?.name,
            id : productId,
            status : singleProductData?.status,
            varient : singleProductData?.varient ? singleProductData?.varient?.map((data)=> data) : !!getVarientWithoutPageList && getVarientWithoutPageList?.map((data) => data?.id)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-5">
                <Col md={6}>
                  <img alt="home" className="w-auto" src="https://booster.io/wp-content/uploads/product-add-to-cart-e1438362099361.png"/>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={12} className="mb-5">
                      <Form.Group >
                        <Form.Label>Modal Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Modal Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Row>
                    <Col md={8} sm={8} className="mb-5">
                        <Form.Label>
                          Select Varients
                        </Form.Label>
                        <Select
                        className="border"
                          value = {
                              !!getVarientWithoutPageList &&
                              getVarientWithoutPageList?.map((data) => {
                                if (values?.varient?.length && values.varient.indexOf(data?.id) != -1) {
                                  return {
                                    value: data?.id,
                                    label: data?.name,
                                  };
                                }
                              })
                          }
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              `varient`,
                              selectedOptions?.map((data) => data?.value)
                            );
                          }}
                          options={
                          !!getVarientWithoutPageList &&
                          getVarientWithoutPageList.map((data) => {
                          return {
                                  value: data?.id,
                                  label: data?.name,
                                };
                              })
                            }
                          />
                      </Col> 
                      <Col md={4} className="mt-4 p-0">
                        <VarientAction
                        title="Add Varient"
                        btnTitle="Add New Varient"
                        api={addVarient}
                        btnwdth={"w-100 mt-2"}/>
                      </Col> 
                    </Row>
                    <Col>
                      <Button variant="success" className="w-100" type="submit">
                        Submit
                      </Button>    
                      </Col>
                  </Row>  
                </Col>     
              </Row>
            </Form>
          )}
        </Formik>
    </>
  );
}

export default ProductAction;
