import React, { useEffect } from 'react';
import logo from './images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { webBaseUrl } from './const';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export default function Paymentgateway() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { addOrderDetails, pendingAmountDetails } = useSelector((state) => state.commonReducer);

    async function displayRazorpay() {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            alert('Razropay failed to load!!');
            return;
        }

        const options = {
            "key": "rzp_live_0kD10GZUkLnKtN",
            "amount": addOrderDetails?.amount ? addOrderDetails?.amount : pendingAmountDetails?.amount,
            "currency": "INR",
            "name": "NVT Thresher",
            "description": "Order Payment",
            "image": logo,
            "order_id": addOrderDetails?.id ? addOrderDetails?.id : pendingAmountDetails?.id,
            "callback_url": `https://b2b.nvtthresher.in/api/razorpay/returnurl/${addOrderDetails?.notes?.paymentId ? addOrderDetails?.notes?.paymentId : pendingAmountDetails?.notes?.paymentId}`,
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "modal": {
              "ondismiss": function () {
               window.location.replace(`${webBaseUrl}/bank-details`)
              }
          },
            "theme": {
                "color": "#3399cc"
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    useEffect(() => {
        dispatch(displayRazorpay());
    }, []);

    return (
        <>
        </>
    );
}
