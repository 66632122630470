import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogOut } from "../reducers/commonReducer";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import AP from "../images/logo.jpg";
import { Col, NavDropdown, Row } from "react-bootstrap";

const HeaderMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);

  const logout = async () => {
    const apiResponse = await dispatch(userLogOut());
    if (apiResponse?.payload?.status === 1) {
      localStorage.clear();
      return navigate("/");
    }
    return null;
  };
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3 align-baseline">
          <div className="col-6">
            <Navbar.Brand>
              <NavLink to="/dashboard">
                <img src={AP} alt="logo"/>
              </NavLink>
            </Navbar.Brand>
          </div>
          <div className="col-6 text-end">
            <Button type="submit" className="button-color" onClick={logout}>
              Log Out{" "}
            </Button>
          </div>
        </div>
      </div>

      <Navbar
        collapseOnSelect
        className="header-bg-color"
        expand="lg"
        variant="dark"
      >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto menu-bar dashboard">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {
                  (localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && <>
                  <li className="nav-item nav-bar">
                  <NavLink to="/dashboard">
                  <i className="bi bi-check2-square"></i>
                    Dashborad
                  </NavLink>
                </li>
                <li className="nav-item nav-bar">
                  <NavLink to="/users">
                  <i className="bi bi-person-lines-fill"></i>
                    Dealers
                  </NavLink>
                </li>
                <li className="nav-item nav-bar">
                  <NavLink to="/orders">
                  <i className="bi bi-columns-gap"></i>
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item nav-bar">
                  <NavLink to="/payment">
                  <i className="bi bi-cash-stack"></i>
                    Payments
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/products">
                    <i className="bi bi-bag-dash"></i>
                    Modal 
                  </NavLink> 
                </li> */}  
                  <li className="nav-item drop-icon dropdown-active">  
                  <NavDropdown title={<span><i className="bi bi-bag"></i> Product</span>} id="navbarScrollingDropdown">
                <Row className="sub-menu-text subnavbaar">
                  <Col md={12}><NavLink className="w-100" to="/products">Modal</NavLink></Col>
                  <Col md={12}><NavLink className="w-100" to="/varient">Varient</NavLink></Col>
                  <Col md={12}><NavLink className="w-100" to="/spare-part">Spare Part</NavLink></Col>
                  <Col md={12}><NavLink className="w-100" to="/crops-to-be-threshed">Crops Threshed</NavLink></Col>
                </Row>
              </NavDropdown>
                  </li>
                
                  <li className="nav-item dropdown-active">  
              <NavDropdown title={<span><i className="bi bi-bookmarks"></i> Exemplar</span>} id="navbarScrollingDropdown"> 
                <Row className="sub-menu-text subnavbaar">
                  <Col md={12}><NavLink className="w-100" to="/internaluser">Internal Users</NavLink></Col>
                  <Col md={12}><NavLink className="w-100" to="/coupons">Coupon Code</NavLink></Col>
                </Row>
              </NavDropdown> 
              </li>
                <li className="nav-item nav-bar">
                  <NavLink to="/complaints">
                  <i className="bi bi-telephone-forward"></i>
                    Complaints
                  </NavLink>
                </li>
                <li className="nav-item nav-bar">
                  <NavLink to="/types">
                  <i className="bi bi-phone"></i>
                    Complaint Types
                  </NavLink>
                </li>
                  </>
                }
                {
                  localStorage.getItem("userRole") === "manager" && <>
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    Dealers
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/orders">
                    <i className="bi bi-people"></i>
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/complaints">
                    <i className="bi bi-people"></i>
                    Complaints
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payment">
                    <i className="bi bi-people"></i>
                    Payments
                  </NavLink>
                </li>
                  </>
                }
                {/* dealer menu */}
                {
                  (localStorage.getItem("userRole") === "dealer") && <>
                <li className="nav-item">
                  <NavLink to="/orders">
                    <i className="bi bi-columns-gap"></i>
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/orders-action">
                    <i className="bi bi-columns-gap"></i>
                    Add Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/complaints">
                    <i className="bi bi-person-lines-fill"></i>
                    Complaints
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/complaint">
                    <i className="bi bi-person-lines-fill"></i>
                    Add Complaint
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payment">
                    <i className="bi bi-cash-stack"></i>
                    Payments
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/transaction">
                    <i className="bi bi-cash-stack"></i>
                    Transaction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/bank-details">
                  <i className="bi bi-bank2"></i>
                    NVT BankDetails
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/signup">
                    <i className="bi bi-people"></i>
                    Profile
                  </NavLink>
                </li>
                <li className="nav-item bg-warning rounded-pill">
                  <NavLink className="px-2">
                    Dealer Code : {localStorage.getItem("dealerId")}
                  </NavLink>
                </li>
                 </>
                }
              </ul>
            </Nav>
            <Nav className="d-none">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item"></li>
                <li className="nav-item settings">
                  <NavLink to="/settings">
                    Settings{" "}
                    <span>
                      <i className="bi bi-gear-fill"></i>
                    </span>
                  </NavLink>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default HeaderMenu;
