import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getCategories } from "../../reducers/commonReducer";
import Add from "./Add";
import Update from "./Update";
import red from "../../images/red.png";
import green from "../../images/green.png";

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { getCategoriesData } = useSelector((state) => state.commonReducer);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Category List</h3>
          </Col>
          <hr />
          <Col md={6}>
            <Button
              className="mb-3 mobile-width"
              variant="primary"
              onClick={handleShow}
            >
              Add Category
            </Button>
          </Col>
        </Row>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Name</th>
                <th>Description</th>
                {/* <th>Products</th>
                <th>Complaints</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!getCategoriesData?.list &&
                getCategoriesData?.list.map((data, index) => {
                  return (
                    <tr key={data.id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.description}</td>
                      {/* <td>
                        <NavLink to={`/products?catid=${data?.id}`}>
                          <Button className="m-1" variant="success">
                            {data?.products}
                          </Button>
                        </NavLink>
                      </td>
                      <td>
                        <NavLink to={`/complaints?complaintsid=${data?.id}`}>
                          <Button className="m-1" variant="success">
                            {data?.complaints}
                          </Button>
                        </NavLink>
                      </td> */}
                      <td className="table-img">
                        {data?.status === true ? (
                          <img src={green} alt="active"/>
                        ) : (
                          <img src={red} alt="inactive"/>
                        )}
                      </td>
                      <td>
                        <Update
                          title="Update Category"
                          name={data?.name}
                          description={data?.description}
                          status={data?.status}
                          id={data?.id}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>

      <Add showhide={show} handleClose={handleClose} />
    </>
  );
};

export default List;
