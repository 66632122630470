import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, updateUser } from "../../reducers/commonReducer";
import { useLocation } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email("Invalid email address").required(),
  address: yup.string().required("Please Enter Address."),
  city: yup.string().required("Please Enter city."),
  state: yup.string().required("Please Enter state."),
  phone: yup.string().min(10).max(10).required(),
  status: yup.string().required(""),
  role:yup.string().required("Please Assign User Role"),
  type: yup.array().when('role', {
    is: 'manager',
    then: yup.array().required('Manager type is required'),
  }),
});
function Update(props) {
  const {fName,userAdrs,userCity,userState,userPhone,userEmail,status,id,userRole,firmname,
    gst,
    gstNo,
    msme,
    aadhar,
    bank,
    secondPhone} = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { getEnabledTypesData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Formik
        validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(updateUser(values));
            resetForm({ values: "" });
            dispatch(getUsers({
              role:location.pathname === "/internaluser" ? "manager" : "dealer"
            }));
            setShow(false);
          }}
          initialValues={{
            name: fName,
            address: userAdrs,
            city: userCity,
            state: userState,
            phone: userPhone,
            email: userEmail,
            id,
            status: status,
            password:'',
            role:userRole,
            firmname,
            gst,
            gstNo,
            msme,
            aadhar,
            bank,
            secondPhone
            // type:!!userType && userType?.map((data) => data?.id)
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="city"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                {
                  values.role === "manager" && <Col md={12}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="type"
                    multiple={true}
                    className="form-control"
                    value={values.type}
                    isInvalid={!!errors.type}
                  >
                    {
                      !!getEnabledTypesData && getEnabledTypesData?.map((data, index) => {
                        return <option value={data?.id} key={index}>
                        {data?.name}
                        </option>
                      })
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                </Col>
                }
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          className="form-control"
                          placeholder="Password"
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true} selected>
                      True
                    </option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
              </Row>
              {userRole === "dealer" && <>
                <Row className="mb-3">
                  <Col md={3} sm={12}>
                        <Form.Group>
                          <Form.Label>FirmName</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Firm Name"
                              name="firmname"
                              value={values.firmname}
                              onChange={handleChange}
                              isInvalid={!!errors.firmname}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firmname}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                      <Form.Group as={Col} md="12">
                        <Form.Label>GST</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="gst"
                          onChange={(event) => {
                            const gstImg = event.target.files[0];
                            setFieldValue("gst", gstImg);
                          }}
                          isInvalid={!!errors.gst}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.gst}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group>
                          <Form.Label>GST No</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter GST No."
                              name="gstNo"
                              value={values.gstNo}
                              onChange={handleChange}
                              isInvalid={!!errors.gstNo}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.gstNo}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group>
                          <Form.Label>Second Phone</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Second Phone"
                              name="secondPhone"
                              value={values.secondPhone}
                              onChange={handleChange}
                              isInvalid={!!errors.secondPhone}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.secondPhone}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                 <Col md={4} sm={12}>
                       <Form.Group>
                         <Form.Label>MSME</Form.Label>
                           <Form.Control
                             type="text"
                             placeholder="Enter MSME"
                             name="msme"
                             value={values.msme}
                             onChange={handleChange}
                             isInvalid={!!errors.msme}
                           />
                           <Form.Control.Feedback type="invalid">
                             {errors.msme}
                           </Form.Control.Feedback>
                       </Form.Group>
                 </Col>
                 <Col md={4} sm={12}>
                       <Form.Group>
                         <Form.Label>Aadhar Number</Form.Label>
                           <Form.Control
                             type="number"
                             placeholder="Enter Aadhar Number"
                             name="aadhar"
                             value={values.aadhar}
                             onChange={handleChange}
                             isInvalid={!!errors.aadhar}
                           />
                           <Form.Control.Feedback type="invalid">
                             {errors.aadhar}
                           </Form.Control.Feedback>
                       </Form.Group>
                 </Col>
                 <Col md={4} sm={12}>
                       <Form.Group>
                         <Form.Label>Bank Details</Form.Label>
                           <Form.Control
                             type="text"
                             placeholder="Write Bank Details..."
                             as="textarea"
                             row={3}
                             name="bank"
                             value={values.bank}
                             onChange={handleChange}
                             isInvalid={!!errors.bank}
                           />
                           <Form.Control.Feedback type="invalid">
                             {errors.bank}
                           </Form.Control.Feedback>
                       </Form.Group>
                 </Col>
               </Row>
              </>
              }
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default Update;
