import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Header from "../../Components/Header";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addPayment, getPayment, getPayments, updatePayment} from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import payment from '../../images/payment.jpg'
import moment from "moment";
const schema = yup.object().shape({
  amount: yup.string().required(),
  mode: yup.string().required(),
  transactionid : yup.string().required(),
  date : yup.string().required(),
  image: yup.mixed().required("Please Upload GST"),
});

function PaymentAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const paymentid = queryParameters.get("paymentid");

  useEffect(()=>{
    if(paymentid){
      dispatch(getPayment({
        id : paymentid
       }))
    }
  },[])
  const { getPaymentDetails } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header/>
      <Row>
        <Col md={6}>
          <img className="w-100" src={payment}/>
        </Col>
        <Col md={6} className="p-5">
        <Formik
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async(values, { resetForm }) => {
           const result = paymentid ? await dispatch(updatePayment(values)) : await dispatch(addPayment(values));
           if(result?.payload?.status){
            setTimeout(() => {
              navigate(`/payment`)
            }, 1000)
           }
            resetForm({ values: "" });
            dispatch(getPayments())
          }}
          initialValues={{
            id:paymentid,
            amount:getPaymentDetails?.amount,
            mode:getPaymentDetails?.mode,
            transactionid:getPaymentDetails?.transactionid,
            date:moment(getPaymentDetails?.date).format('YYYY-MM-DD'),
            status:getPaymentDetails?.status,
            image:getPaymentDetails?.image,
            comment:getPaymentDetails?.comment
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Amount"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      isInvalid={!!errors.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Mode</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("mode", e.target.value);
                    }}
                    name="mode"
                    className="form-control"
                    value={values.mode}
                    isInvalid={!!errors.mode}
                  >
                    <option value="">Select Mode</option>
                    <option value={"Credit cards"}>Credit cards</option>
                    <option value={"Bank transfers"}>Bank transfers</option>
                    <option value={"UPI"}>UPI</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group >
                    <Form.Label>Transaction ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Transactionid"
                      name="transactionid"
                      value={values.transactionid}
                      onChange={handleChange}
                      isInvalid={!!errors.transactionid}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.transactionid}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group >
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Date"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      isInvalid={!!errors.date}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                  <Col md={6} sm={12}>
                    <Form.Group as={Col} md="12">
                      <Form.Label>Payment Image</Form.Label>
                      <Form.Control
                        id="myfile"
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={(event) => {
                          const complaintImg = event.target.files[0];
                          setFieldValue("image", complaintImg);
                        }}
                        isInvalid={!!errors.image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {localStorage.getItem("userRole") !== "dealer" && <Col md={6} sm={12}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("status", e.target.value);
                      }}
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={0}>Pending</option>
                      <option value={1}>Verify</option>
                    </Form.Control>
                  </Col>}
                </Row>
                {localStorage.getItem("userRole") !== "dealer" &&
                <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group >
                    <Form.Label>Review</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Write Review....."
                      row={4}
                      as="textarea"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      isInvalid={!!errors.comment}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.comment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                </Row>}
              <Row>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
        </Col>
      </Row>
    </>
  );
}

export default PaymentAction;
