import React, { useEffect } from "react";
import { Col, Button, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import SBK from "../../images/logo.jpg";
import { useDispatch } from "react-redux";
import { sendOtp, verifyOtp } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  phone: yup.string().required(),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (values) => {
    const apiResponse = await dispatch(sendOtp(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/verify-otp");
      }, 1000);
    }
    return null;
  };

  return (
    <>
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={SBK} alt="logo" />
            </div>
            <div className="text-center">
              <p>
                <b> Send OTP </b>
              </p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                login(values);
                resetForm({ values: "" });
              }}
              initialValues={{
                phone: localStorage.getItem("mobile"),
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-4 mb-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                        <i className="bi bi-phone"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          className="form-control"
                          placeholder="Enter Mobile No."
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center">
                    <Button className="btn-color w-100" type="submit">
                      Send OTP
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="col-12 text-center">
            <p>
              <Link to={"/"}>
                <b>
                  <i className="bi bi-arrow-left"></i> Back to Home
                </b>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
