import React, { useRef, useCallback,useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import { assignComplaint,resetComplaints, complaintFeedback, getComplaint, updateComplaint } from "../../reducers/commonReducer";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
const videoConstraints = {
  camRfacingMode: FACING_MODE_USER
};
const schema = yup.object().shape({
  id: localStorage.getItem("userRole") === "manager" && yup.string().required("Please select Technician."),
  complaint: yup.string().required(),
  status: localStorage.getItem("userRole") === "technicain" && yup.string().required("Please select Complaint Status."),
});

function ComplaintUpdate(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [img, setImg] = useState(null);
  const [techName, setTechName] = useState('');
  const [camRfacingMode, setcamRFacingMode] = React.useState(FACING_MODE_USER);
  const webcamRef = useRef(null);
  const {
    title,
    complaintid,location,
    complaintStatus
  } = props;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { technicainsList } = useSelector((state) => state.commonReducer);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);
  const flipCamera = React.useCallback(() => {
    setcamRFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);
  return (
    <>
      <Button variant="success" className="float-right px-2 py-1" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <hr/>
        <Formik
          validationSchema={complaintStatus !== 2 && schema}
          enableReinitialize={true}
          onSubmit={async (values, { resetForm }) => {
            if (((localStorage.getItem("userRole") === "manager" || localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && !techName?.length )) {
               dispatch(updateComplaint({
                status: values?.status,
                id: values?.complaint
              }));
            } 
            else if(((localStorage.getItem("userRole") === "manager" || localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && techName?.length > 0)){
               dispatch(assignComplaint({
                id: values?.id,
                complaint: values?.complaint
              }));
            }
            else if (localStorage.getItem("userRole") === "technicain" || localStorage.getItem("userRole") === "customer") {
               dispatch(complaintFeedback({
                image: values?.image,
                complaint: values?.complaint,
                star: values?.star,
                commet: values?.commet,
                status:values?.status,
                rLatitude: location?.latitude,
                rLongitude: location?.longitude,
              }));
            } else {
              // Handle other roles or cases if necessary.
            }
            resetForm({ values: "" });
            dispatch(resetComplaints());
            setTimeout(() => {
              dispatch(getComplaint())
            }, 500)
            setShow(false)
          }}
          initialValues={{
            id:'',
            complaint:complaintid,
            status:'',
            commet:'',
            star:'',
            image:img,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              {
                ((localStorage.getItem("userRole") === "manager" || localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && complaintStatus !== 2 )&& <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Select Technician</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("id", e.target.value);
                      const selectedName = e.target.selectedOptions[0].getAttribute('data-name');
                      setTechName(selectedName)
                    }}
                    name="id"
                    className="form-control"
                    value={values.id}
                    isInvalid={!!errors.id}
                  >
                    <option value="">Select Technician</option>
                    {!! technicainsList && technicainsList?.map((data, index) => {
                      return (
                        <option value={data.id} data-name={data?.name}>{data.name}</option>
                      );  
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.id}
                    </Form.Control.Feedback>
                </Col>
                </Row>
              }
              {
                ((localStorage.getItem("userRole") === "manager" || localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin" )) && <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Complaint Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={3}>Complete</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Col>
                </Row>
              }
                {
                  localStorage.getItem("userRole") === "technicain" && <>
                  <Row className="mb-3">
                  <Col md={12}>
                    <Form.Label>Complaint Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={2}>Review</option>
                      <option value={4}>Progress</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Col>
                  </Row>
                  <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group >
                    <Form.Label>Customer Review</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Review......"
                      name="commet"
                      value={values.commet}
                      onChange={handleChange}
                      isInvalid={!!errors.commet}
                      as="textarea" rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.commet}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                </Row>
                <Row className="mb-3">
                {img === null ? (
                    <>
                      <Webcam
                        audio={false}
                        // mirrored={true}
                        // height={'100%'}
                        // width={'100%'}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                          ...videoConstraints,
                          facingMode : camRfacingMode
                        }}
                      />
                     <Row className="text-center">
                      <Col md={6} xm={6} sm={6} >
                      <Button onClick={capture}>Capture photo</Button>
                      </Col>
                      <Col md={6} xm={6} sm={6}>
                      <Button onClick={flipCamera}>Rotate Camera</Button>
                      </Col>
                     </Row>
                    </>
                  ) : (
                    <>
                      <img src={img} alt="screenshot" />
                      <Button onClick={() => setImg(null)}>Retake</Button>
                    </>
                  )}
                  {/* <Col md={12} sm={12}>
                    <Form.Group as={Col} md="12">
                      <Form.Label>Review Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="image"
                        onChange={(event) => {
                          const reviewimg = event.target.files[0];
                          setFieldValue("image", reviewimg);
                        }}
                        isInvalid={!!errors.image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col> */}
                  </Row>
                  <Row className="mb-3">
                  <Col md={12}>
                    <Form.Label>Rating</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="star"
                      className="form-control"
                      value={values.star}
                      isInvalid={!!errors.star}
                    >
                      <option value="">Select Rating</option>
                      <option value={1}>⭐</option>
                      <option value={2}>⭐⭐</option>
                      <option value={3}>⭐⭐⭐</option>
                      <option value={4}>⭐⭐⭐⭐</option>
                      <option value={5}>⭐⭐⭐⭐⭐</option>
                    </Form.Control>
                  </Col>
                  </Row>
                  </>
                }
                {localStorage.getItem("userRole") === "customer" && <>
                   <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group >
                    <Form.Label>Customer Review</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Review......"
                      name="commet"
                      value={values.commet}
                      onChange={handleChange}
                      isInvalid={!!errors.commet}
                      as="textarea" rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.commet}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group as={Col} md="12">
                      <Form.Label>Review Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={(event) => {
                          const reviewimg = event.target.files[0];
                          setFieldValue("image", reviewimg);
                        }}
                        isInvalid={!!errors.image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  </Row>
                  <Row className="mb-3">
                  <Col md={12}>
                    <Form.Label>Rating</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="star"
                      className="form-control"
                      value={values.star}
                      isInvalid={!!errors.star}
                    >
                      <option value="">Select Rating</option>
                      <option value={1}>⭐</option>
                      <option value={2}>⭐⭐</option>
                      <option value={3}>⭐⭐⭐</option>
                      <option value={4}>⭐⭐⭐⭐</option>
                      <option value={5}>⭐⭐⭐⭐⭐</option>
                    </Form.Control>
                  </Col>
                  </Row>
                  </>
                }
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ComplaintUpdate;
