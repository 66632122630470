import React from "react";
import logo from "../images/logo.jpg";
import { Col, Container, Row } from "react-bootstrap";

const Disclaimer = () => {
  return (
    <Container>
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
            <h1><b>Disclaimer</b></h1>
           <p>By accessing b2b.nvtthresher.in, you have read, understood and agree to be legally bound by the terms of the following disclaimer and user agreement b2b.nvtthresher.in has taken due care and caution in compilation of data for its web site.</p>
           <p>b2b.nvtthresher.in advises users to check with certified experts before taking any investment decision. However, b2b.nvtthresher.in does not guarantee the accuracy, adequacy or completeness of any information and is not responsible for any errors or omissions or for the results obtained from the use of such information. b2b.nvtthresher.in especially states that it has no financial liability whatsoever to any user on account of the use of information provided on its website.</p>
           <p>b2b.nvtthresher.in is not responsible for any errors, omissions or representations on any of our pages or on any links on any of our pages. b2b.nvtthresher.in does not endorse in anyway any advertisers on our web pages. Please verify the veracity of all information on your own before undertaking any alliance</p>
           <p>The information on this website is updated from time to time. b2b.nvtthresher.in however excludes any warranties (whether expressed or implied), as to the quality, accuracy, efficacy, completeness, performance, fitness or any of the contents of the website, including (but not limited) to any comments, feedback and advertisements contained within the Site.</p>
           <p>b2b.nvtthresher.in makes no warranty that the contents of the website are free from infection by viruses or anything else which has contaminating or destructive properties and shall have no liability in respect thereof.</p>
           <p>Part of this website contains advertising and other material submitted to us by third parties. Kindly note that those advertisers are responsible for ensuring that material submitted for inclusion on the website complies with all legal requirements. Although acceptance of advertisements on the website is subject to our terms and conditions which are available on request, we do not accept liability in respect of any advertisements.</p>
           <p>There are risks associated with utilizing internet and short messaging system (sms) based information and research dissemination services. Subscribers are advised to understand that the services can fail due to failure of hardware, software, and Internet connection. While we ensure that the messages are delivered in time to the subscribers Mobile Network, the delivery of these messages to the customer's mobile phone/handset is the responsibility of the customer's Mobile Network. SMS may be delayed and/or not delivered to the customer's mobile phone/handset on certain days, owing to technical reasons that can only be addressed by the customer's Mobile Network, and b2b.nvtthresher.in and/or its employees cannot be held responsible for the same.</p>
           <p>b2b.nvtthresher.in hereby expressly disclaims any implied warranties imputed by the laws of any jurisdiction. We consider ourselves and intend to be subject to the jurisdiction only of the courts of the Mumbai in India. If you don't agree with any of our disclaimers above please do not read the material on any of our pages. This site is specifically for users in the territory of India. Although the access to users outside India is not denied, b2b.nvtthresher.in shall have no legal liabilities whatsoever in any laws of any jurisdiction other than India.We reserve the right to make changes to our site and these disclaimers, terms, and conditions at any time. Commodity trading is inherently risky and you agree to assume complete and full responsibility for the outcomes of all trading decisions that you make, including but not limited to loss of capital. None of the trading calls made by b2b.nvtthresher.in should be construed as an offer to buy or sell, nor advice to do so. Under no circumstances should any person at this site make trading decisions based solely on the information discussed herein. We are not a qualified financial advisor and you should not construe any information discussed herein to constitute investment advice. It is informational in nature.</p>
           <p>You should consult a qualified broker or other financial advisor prior to making any actual investment or trading decisions. You agree to not make actual commodity trades based on comments on the site, nor on any techniques presented nor discussed in this site or any other form of information presentation. All information is for educational and informational use only. You agree to consult with a registered investment advisor, which we are not, prior to making any trading decision of any kind. Hypothetical or simulated performance results have certain inherent limitations. Unlike an actual performance record, simulated results do not represent actual trading. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown.</p>
           <p>We encourage all investors to use the information on the site as a resource only to further their own research on commodity markets. Nothing published on this site should be considered as investment advice. b2b.nvtthresher.in and/or their employees take no responsibility for the veracity, validity and the correctness of the expert recommendations or other information or research. Although we attempt to research thoroughly on information provided herein, there are no guarantees in accuracy. The information presented on the site has been gathered from various sources believed to be providing correct information. b2b.nvtthresher.in and/or their employees are not responsible for errors, inaccuracies if any in the content provided on the site.</p>
           <p>Any b2b.nvtthresher.in and/or their owners, officers and employees may have positions in any commodity mentioned in this website and may from time to time add or dispose off such commodities.</p>
           <p>b2b.nvtthresher.in does not represent or endorse the accuracy or reliability of any of the information, conversation, or content contained on, distributed through, or linked, downloaded or accessed from any of the services contained on this website (hereinafter, the "Service"), nor the quality of any products, information or other materials displayed, purchased, or obtained by you as a result of any other information or offer by or in connection with the Service.</p>
            <p>By using b2b.nvtthresher.in including any software and content contained therein, you agree that use of the Service is entirely at your own risk. b2b.nvtthresher.in is not a registered investment advisor or a broker dealer. You understand and acknowledge that there is a very high degree of risk involved in trading commodities. Past results of any trader published on this website are not indicative of future returns by that trader, and are not indicative of future returns which be realized by you. Any information, opinions, advice or offers posted by any person or entity logged in to b2b.nvtthresher.in or any of its associated sites is to be construed as public conversation only. b2b.nvtthresher.in makes no warranties and gives no assurances regarding the truth, timeliness, reliability, or good faith of any material posted at b2b.nvtthresher.in. b2b.nvtthresher.in does not warrant that trading methods or systems presented in their services or the information herein, or obtained from advertisers or members will result in profits or losses.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Disclaimer;
