import React, { Fragment, useEffect } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getProducts,getEnabledCategories, updateProduct } from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const catid = queryParameters.get("catid");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getProducts());
  }, []);
  const { getProductsData } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Modal List</h3>
          </Col>
          <hr />
          <Col md={6}>
           <Link to={`/products-action`}>
            <Button variant="success">Add Modal</Button>
           </Link>
          </Col>
          <Col className="text-end" md={6}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
                dispatch(
                  getProducts({
                    search:
                      e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1),
                  })
                );
              }}
            />
          </Col>
        </Row>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Modal Name</th>
                <th>Varient</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!getProductsData
                ? getProductsData?.map((data, index) => {
                    return (
                      <tr key={data.id}>
                        <td>{index + 1}.</td>
                        <td>{data?.name}</td>
                        <td>{data?.varient?.map((item, itemIndex) => <Button className="m-1" key={itemIndex} variant="secondary">{item?.name}</Button>)}</td>
                        <td className="table-img"
                        onClick={async() => {
                          const modalStatus = window.confirm("Are you sure for update modal status.");
                          if(modalStatus){
                            await dispatch(updateProduct({
                              id:data?.id,
                              status:!data?.status
                            }))
                           await dispatch(getProducts())
                          }
                        }}
                        >
                          {data?.status === true ? (
                            <img src={green} alt="active"/>
                          ) : (
                            <img src={red} alt="inactive"/>
                          )}
                        </td>
                        <td>
                          <Link to={`/products-action?pid=${data?.id}`}>
                            <Button className="button-10 text-white" variant="">Update</Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ProductList;
